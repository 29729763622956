import { WebApplication } from "../webApplication";
import { FadeoutTimer } from "./FadeoutTimer.js";

export class HTMLScreenOverlay {
    static set_debug_table_x_offset(amount: number) {
        //for (const each_name of this.container_names) {
        const each = document.getElementById("debug-table");
        if (!each) {
            return;
        }
        each.style.marginLeft = `${amount}px`;
        //}
    }

    application: WebApplication;
    container_names: string[];
    fadeout: FadeoutTimer | undefined;
    enabled: boolean;
    timeout: number | undefined;
    enabled_setting: string;
    enabled_timeout_setting: string;

    x_offset: number;
    constructor() {}
    update() {}
    // static initialize(self, application){
    //   self.application = application;
    //   self.elements = {};
    // }

    initialize(application: WebApplication) {
        this.application = application;

        this.enabled = this.application.getSetting(this.enabled_setting);
        this.timeout = this.application.getSetting(this.enabled_timeout_setting);

        this.application.addSettingSubscription(
            this.enabled_setting,
            this.onEnabledChanged.bind(this),
        );
    }

    onEnabled() {
        this.show();
        this.fadeout?.start();
    }
    onEnabledChanged(value: any) {
        this.enabled = value;
        if (!value) {
            this.fadeout?.stop();
            this.hide();
        } else {
            this.onEnabled();
        }
    }
    onActivity() {
        this.fadeout?.onActivity();
    }

    hide() {
        for (const each_name of this.container_names) {
            this.hideDiv(each_name);
        }
    }

    hideDiv(each_name: string) {
        const each = document.getElementById(each_name);
        if (!each) {
            return;
        }
        each.style.display = "none";
    }

    show() {
        for (const each_name of this.container_names) {
            this.showDiv(each_name);
        }
    }

    showDiv(each_name: string) {
        const each = document.getElementById(each_name);
        if (!each) {
            return;
        }
        each.style.display = "block";
    }
    set_x_offset(amount: number) {
        this.x_offset = amount;

        for (const each_name of this.container_names) {
            const each = document.getElementById(each_name);
            if (!each) {
                continue;
            }
            each.style.marginLeft = `${amount}px`;
        }
    }
    setupFadeout() {
        if (this.timeout !== undefined) {
            this.fadeout = new FadeoutTimer(this, this.timeout * 60);
        }

        if (!this.enabled) {
            this.hide();
            return;
        }

        if (this.timeout !== undefined) {
            this.fadeout?.start();
        }
    }
}
