import { ReadonlyProperty } from "./view/propertyEditor";
import { StackLayout } from "./view/stackLayout";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface";
import { MemoryDiagnostics } from "./MemoryDiagnostics";
import { WebApplicationSettings } from "./WebApplicationSettings";
import { Resources } from "./resources.js";
import { WebApplicationServer } from "./webApplicationServer";
import { InteractivePlatformCanvas } from "./audio_visual/InteractivePlatformCanvas";
import { onValueChanged } from "./ValueChangedSubscriptions";
import { Treeview } from "./view/treeview";
import { WebApplicationState } from "./WebApplicationState";
import { LocalServerFileCache } from "./LocalServerFileCache";

export class WebApplication {
    static isAudioVisualScriptsEnabled_SettingName = "audio visual scripts enabled";
    static isAudioVisualScriptsEnabled_SettingDefaultValue = true;
    static isPreloadConnectedSceneGraphContentEnabled_SettingName =
        "Preload Connected SceneGraph Content";
    static isPreloadConnectedSceneGraphContentEnabled_SettingDefaultValue = true;
    static isVideoPlayingTimeoutSettingName = "video playing timeout";
    static isVideoPlayingTimeoutSettingDefaultValue = false;
    static isShowMemoryDiagnosticsOverlaySettingName = "Show Memory Diagnostics Overlay";
    static isShowMemoryDiagnosticsOverlaySettingDefaultValue = false;
    //static isMemoryDiagnosticsOverlayTimeoutSettingName = "Memory Diagnostics Overlay Inactivity Timeout Minutes";
    //static isMemoryDiagnosticsOverlayTimeoutSettingDefaultValue=5;
    static isShowConsoleLogOverlaySettingName = "Show Console Log Overlay";
    static isShowConsoleLogOverlaySettingDefaultValue = false;
    static isConsoleLogOverlayTimeoutSettingName = "Console Log Overlay Inactivity Timeout Minutes";
    static isConsoleLogOverlayTimeoutSettingDefaultValue = 5;
    static isShowDebugOverlaySettingName = "Show Debug Overlay";
    static isShowDebugOverlaySettingDefaultValue = true;
    static isShowDetailedDebugOverlaySettingName = "Show Detailed Debug Overlay";
    static isShowDetailedDebugOverlaySettingDefaultValue = false;
    static isDebugOverlayTimeoutSettingName = "Debug Overlay Inactivity Timeout Minutes";
    static isDebugOverlayTimeoutSettingDefaultValue = 0.5;
    static isLoadApplicationLocalStorageSettingName = "isLoadApplicationLocalStorage";
    static isLoadApplicationLocalStorageSettingDefaultValue = true;
    static isSaveApplicationLocalStorageSettingName = "isSaveApplicationLocalStorage";
    static isSaveApplicationLocalStorageSettingDefaultValue = true;
    static isDeleteBeforeSaveApplicationLocalStorageSettingName =
        "isDeleteBeforeSaveApplicationLocalStorage";
    static isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue = true;
    static isLoadAccountLocalStorageSettingName = "isLoadAccountLocalStorage";
    static isLoadAccountLocalStorageSettingDefaultValue = false;
    static isSaveAccountLocalStorageSettingName = "isSaveAccountLocalStorage";
    static isSaveAccountLocalStorageSettingDefaultValue = false;
    static isPreCacheAssetsSettingName = "isPreCacheAssets";
    static isPreCacheAssetsSettingDefaultValue = false;
    static isUseRESTSettingName = "isUseREST";
    static isUseRESTSettingDefaultValue = false;
    static IsVerboseLoggingSettingName = "verbose logging";
    static IsVerboseLoggingSettingDefaultValue = true;
    static IsVideoEnabledSettingName = "video enabled";
    static IsVideoEnabledSettingDefaultValue = true;
    static IsMSVideoEnabledSettingName = "MediaSource video enabled";
    static IsMSVideoEnabledSettingDefaultValue = true;
    // static IsDBVideoEnabledSettingName = "double buffered video enabled";
    // static IsDBVideoEnabledSettingDefaultValue = false;
    // static IsSBVideoEnabledSettingName = "single buffered video enabled";
    // static IsSBVideoEnabledSettingDefaultValue = false;
    static IsPSVideoEnabledSettingName = "per-scene video enabled";
    static IsPSVideoEnabledSettingDefaultValue = false;
    static IsAllCanvasVideosSettingName = "per-scene canvas video enabled";
    static IsAllCanvasVideosSettingDefaultValue = false;
    static VideoFramesPerSecondSettingName = "per-scene canvas video Frames Per Second";
    static VideoFramesPerSecondSettingDefaultValue = 30;
    static IsAuthEnabledSettingName = "auth enabled";
    static IsAuthEnabledSettingDefaultValue = true;
    static IsAuthUsingQAEndpointSettingName = "auth uses QA endpoint";
    static IsAuthUsingQAEndpointSettingDefaultValue = false;
    static isPhoneRemoteEnabledSettingName = "Phone remote enabled";
    static isPhoneRemoteEnabledSettingDefaultValue = true;
    static isWebRTCForRemoteEnabledSettingName = "Enable WebRTC for phone remote";
    static isWebRTCForRemoteEnabledSettingDefaultValue = false;
    static IsAudioEnabledSettingName = "audio enabled";
    static IsAudioEnabledSettingDefaultValue = true;
    static IsWebpEnabledSettingName = "WebP enabled";
    static IsWebpEnabledSettingDefaultValue = true;
    static IsWebpWebAssemblyEnabledSettingName = "WebP web assembly enabled";
    static IsWebpWebAssemblyEnabledSettingDefaultValue = false;

    settings: WebApplicationSettings;
    name: string;
    json: any;
    versionNumber: string | undefined;
    server: WebApplicationServer;
    memory_diagnostics: MemoryDiagnostics;
    platform_canvas: InteractivePlatformCanvas;
    resources: Resources;

    constructor(name = "", versionNumber: string | undefined = undefined) {
        this.name = name;
        this.versionNumber = versionNumber;
        this.memory_diagnostics = new MemoryDiagnostics(this);
    }
    get server_file_cache() {
        return this.server.server_file_cache;
    }

    setServer(server: WebApplicationServer) {
        this.server = server;
    }

    stroageItemName() {
        return Resources.static_combineJsonResourceNameFromArray([
            this.name,
            "application.storage",
        ]);
    }

    createAndInitializeSettings() {
        let settings = new WebApplicationSettings();
        settings.initialize();
        settings.addSettings();
        return settings;
    }

    initialize() {
        this.settings = this.createAndInitializeSettings();

        if (this.getSetting(WebApplication.isLoadApplicationLocalStorageSettingName)) {
            this.json = Resources.static_getLocalStorageJsonItem(this.stroageItemName(), false);
            this.settings.loadFromStorage(this.json);

            if (
                this.getSetting(
                    WebApplicationSettings.isDeleteApplicationLocalStateOnStartup_SettingName,
                )
            ) {
                Resources.static_deleteLocalStorageItem(this.stroageItemName());
                this.settings = this.createAndInitializeSettings();
                this.json = {};
            }
        } else {
            this.json = {};
        }
        //this.saveState();
    }

    addSettingSubscription(name: string, onChanged: onValueChanged) {
        this.settings.addSettingSubscription(name, onChanged);
    }

    removeSettingSubscription(name: string, onChanged: onValueChanged | undefined) {
        if (!onChanged) {
            return;
        }
        this.settings.removeSettingSubscription(name, onChanged);
    }

    setSetting(property: string, v: any, isNotify = true) {
        this.settings.setSetting(property, v, isNotify);
    }

    setDefaultSetting(property: string, v: any) {
        this.settings.setDefaultSetting(property, v);
    }

    toggleBooleanSetting(name: string) {
        this.settings.toggleBooleanSetting(name);
    }

    getSetting(name: string) {
        return this.settings.getSetting(name);
    }

    get settings_json() {
        return this.settings.json.settings;
    }

    /**
     *
     */
    saveState() {
        if (this.getSetting(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName)) {
            Resources.static_deleteLocalStorageItem(this.stroageItemName());
        }

        if (this.getSetting(WebApplication.isSaveApplicationLocalStorageSettingName)) {
            let json_copy = Object.assign({}, this.json, {});
            this.settings.saveToStorage(json_copy);
            Resources.static_setLocalStorageItemAsJson(this.stroageItemName(), json_copy);
        }
    }

    shutdown() {
        this.saveState();
    }

    collectEditableProperties(layout: StackLayout) {
        let version_property = Object.assign(new ReadonlyProperty(), {
            name: "version",
            getValue: () => {
                return this.versionNumber;
            },
        });
        layout.addAsTableRow(version_property.getEditorElements());

        layout.addAsTableRow(
            Object.assign(new ReadonlyProperty(), {
                name: "url",
                getValue: () => {
                    return window.location.href;
                },
            }).getEditorElements(),
        );
    }

    getAuthorInterfaceName() {
        return this.name;
    }

    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        AuthorInterface.collectEditablePropertiesFromProperties(
            this.settings.json.settings,
            this.settings.json.ui.settings,
            this.settings.json.default.settings,
            layout,
            this.settings.setting_subscriptions,
        );
        return layout.element;
    }

    addAuthorInterfaceElementToTreeview(treeview: Treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }

    initializeExternalModules(state: WebApplicationState) {}

    initializeInternalModules(state: WebApplicationState) {}

    initializeSceneModules(state: WebApplicationState) {}

    getS3ContentBucketPath() {
        return "";
    }
}
