import { AuthorInterface } from "../sceneAuthorInterface/authorInterface";
import { ExternalModules } from "./ExternalModules";
import { BaseModules, ModulesCommon } from "./ModulesCommon";

export class InternalModules extends BaseModules {
    external_modules: ExternalModules | undefined;
    authoringInterface: AuthorInterface | undefined;
    json: any;

    constructor(external_modules: ExternalModules, json: any = {}) {
        super();
        this.external_modules = external_modules;
        this.json = json;
    }

    initialize() {}

    saveState() {
        // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }
}
