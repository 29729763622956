import { AudioAmbiance } from "./audioAmbiance";
import { WebAudioInterface } from "./WebAudioComponent";

export class AudioAmbianceSet {

    collection: AudioAmbiance[] = [];
    audio: WebAudioInterface;
    name: string;

    constructor(name: string, audio: WebAudioInterface) {
        this.name = name;
        this.audio = audio;
    }

    addAmbiance(ambiance: AudioAmbiance) {
        this.collection.push(ambiance);
        ambiance.ambianceSet = this;
    }

    removeAmbiance(ambiance: AudioAmbiance) {
        var i = this.collection.indexOf(ambiance);
        if (i != -1) {
            this.collection = this.collection.splice(i, 1);
        }
        ambiance.ambianceSet = undefined;
    }

    // start() {}

    // stop() {}
}
