export class Canvas {
    element: HTMLCanvasElement;
}

import { InteractiveCanvas } from "../interactive_canvas";

export class CanvasStack {
    canvas_stack: Canvas[] = [];
    icanvas: InteractiveCanvas;

    constructor(icanvas: InteractiveCanvas) {
        this.icanvas = icanvas;
    }

    push(canvas: Canvas): void {
        this.canvas_stack.push(canvas);
        this.icanvas.viewport.appendChild(canvas.element);
    }

    pop(canvas?: Canvas): void {
        let popped: Canvas | undefined;

        if (canvas !== undefined) {
            const index = this.canvas_stack.indexOf(canvas);

            if (index < 0) {
                console.warn(`canvas pop not found`);
                return;
            }
            popped = this.canvas_stack[index];
            this.canvas_stack.splice(index, 1);
        } else {
            popped = this.canvas_stack.pop();
        }

        if (popped) {
            this.icanvas.viewport.removeChild(popped.element);
        }
    }
}
