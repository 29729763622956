import { DrawScope } from "./audio_visual/DrawScope";
import { Player } from "./Player";
import { SceneGraphSet } from "./sceneGraph/sceneGraphSet";
import { SceneGraph } from "./sceneGraph/scene_graph";
import { Treeview } from "./view/treeview";
import { SceneGraphNode } from "./sceneGraph/scene_graph_node";
import { NavigationMode, SceneInteractionMode, SelectionMode } from "./input/selectionMode";
import { InteractiveEvent } from "./sceneGraph/InteractiveEvent";
import { ScenePath } from "./sceneGraph/ScenePath";
import { SceneChangeRequest } from "./sceneGraph/SceneChangeRequest";
import { StackLayout } from "./view/stackLayout";
import { RectangleGeometry } from "./geometry/RectangleGeometry";
import { UpdateContext } from "./update";
import { InteractiveCanvas } from "./audio_visual/interactive_canvas";
import { Resources } from "./resources";
import { CustomerAccount } from "./customerAccount";
import { WebApplicationServer } from "./webApplicationServer";
import { WebAudioInterface } from "./audio_visual/audio/WebAudioComponent";
import { WebApplicationState } from "./WebApplicationState";
import { InteractiveMouseEvent } from "./MouseEvent";
import { Geometry } from "./geometry";

export class Simulation {
    static defaultAbsoluteSize = [1920, 1080];

    icanvas: InteractiveCanvas;
    resources: Resources;
    sceneGraphSet: SceneGraphSet;
    json: any;
    account: CustomerAccount;
    server: WebApplicationServer;
    audio: WebAudioInterface;
    sceneGraphNode: SceneGraphNode;
    player: Player;
    selectionMode: SelectionMode;
    state: WebApplicationState;
    geometry: RectangleGeometry;

    constructor(
        resources: Resources,
        icanvas: InteractiveCanvas,
        account: CustomerAccount,
        server: WebApplicationServer,
        audio: WebAudioInterface,
        state: WebApplicationState,
    ) {
        this.state = state;
        this.resources = resources;
        this.icanvas = icanvas;
        this.account = account;
        this.server = server;
        this.sceneGraphSet = new SceneGraphSet(this);

        this.audio = audio;
        this.sceneGraphNode = new SceneGraphNode(this);
        this.selectionMode = new SceneInteractionMode(); //icanvas.platformCanvas.createDefaultSelectionMode();

        this.geometry = RectangleGeometry.createGeometryFromJson_Relative(
            {},
            undefined,
            undefined,
            undefined,
            Geometry.CoordinatesType.ABSOLUTE,
            Geometry.CoordinatesType.ABSOLUTE,
            Simulation.defaultAbsoluteSize,
        );

        this.geometry.letterboxInto = this.icanvas;
    }

    isUXFadedOut() {
        return this.getUXScene()?.isFadedOut() === true;
    }

    incrementItemSet() {
        this.application.memory_diagnostics.increment_item_set();
        this.state.author.console_overlay.increment_item_set();
    }

    get playerSceneGraphNode() {
        return this.player.sceneGraphNode;
    }

    get application() {
        return this.account.application;
    }

    get drawScope() {
        let result = this.account.json_simulation.drawScope;

        if (result === undefined) {
            result = DrawScope.Normal;
        }
        return result;
    }

    set drawScope(v: number) {
        this.account.json_simulation.drawScope = v;
        this.updateCanvasDrawScope();
    }

    async initialize() {
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

        await this.sceneGraphSet.initialize();

        this.updateCanvasDrawScope();

        this.player = new Player(this.account, this);

        this.selectionMode.initialize(this);

        await this.sceneGraphNode.initiaize();
        this.sceneGraphNode.event_scene_change_request_complete = (request) => {
            this.sceneChangeRequestComplete(request);
        };

        //this.icanvas.startBatchUpdate();
        //this.sceneGraphSet.initializeGraphNodeScene(this.sceneGraphNode);

        this.player.startChangeLocation(
            this.player.json.location,
            true,
            ScenePath.onlySceneGraphNameFromJsonPath(this.player.json.location),
        );

        //this.player?.initializeSceneGraphNode();

        //this.icanvas.endBatchUpdate();
        // this.sceneGraphNode.initiaizeEnd();

        //this.initializeSceneGraphNode();
    }

    startSceneChange(
        request: SceneChangeRequest,
        fallbackRequestOnError: SceneChangeRequest | undefined = undefined,
    ) {
        this.application.server.server_file_cache.clearFileBinaryCache();

        this.sceneGraphNode.startSceneChange(request, fallbackRequestOnError);
    }

    sceneChangeRequestComplete(request: SceneChangeRequest) {
        if (request.error) {
            if (ScenePath.areLocationsTheSame(request.location, this.player.json.location)) {
                if (
                    !ScenePath.areLocationsTheSame(request.location, this.player.json.homeLocation)
                ) {
                    this.player.startChangeLocation(this.player.json.homeLocation);
                }
            }
        } else {
            this.player.onSceneChangeRequestSuccess(request);

            //this.sceneGraphNode.selectNone();
            this.selectionMode.startState();
            //this.selectionMode.transitionState(new NavigationMode(this));
        }
    }

    selectNextSceneInteractive(iterationAmount: number) {
        this.sceneGraphNode.selectNextSceneInteractive(iterationAmount);
    }

    getSelectedSceneInteractive() {
        return this.sceneGraphNode.getSelectedSceneInteractive();
    }

    // initializeSceneGraphNode() {

    // }

    updateCanvasDrawScope() {
        this.icanvas.drawScope = this.drawScope;
    }

    start() {}

    drawFrame(icanvas: InteractiveCanvas) {
        this.playerSceneGraphNode.drawFrame(icanvas);
    }

    mousedown(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.playerSceneGraphNode.mousedown(icanvas, e);
    }

    mouseup(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.playerSceneGraphNode.mouseup(icanvas, e);
    }

    mousemove(icanvas: InteractiveCanvas, e: MouseEvent) {
        this.playerSceneGraphNode.mousemove(icanvas, e);
    }

    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        if (ievent.e_keyboardevent?.key === "1") {
            this.toggleDrawScope();
            this.icanvas.invaidate();
        }

        this.selectionMode.keydown(icanvas, ievent);
        if (ievent.isStopPropagation) {
            return;
        }
        this.playerSceneGraphNode.keydown(icanvas, ievent);
    }

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.selectionMode.keyup(icanvas, ievent);
        if (ievent.isStopPropagation) {
            return;
        }
        this.playerSceneGraphNode.keyup(icanvas, ievent);
    }

    stroageItemName() {
        return this.resources.combineJsonResourceNameFromArray([
            this.application.name,
            this.account.name,
            "simulation.storage",
        ]);
    }

    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }

    toggleDrawScope() {
        if (this.drawScope === DrawScope.Normal) {
            this.drawScope = DrawScope.Debug;
        } else {
            this.drawScope = DrawScope.Normal;
        }
    }

    onTouchTap(e: any) {
        this.playerSceneGraphNode.onTouchTap(e);
    }

    onTouchPan(e: any) {
        this.playerSceneGraphNode.onTouchPan(e);
    }

    onTouchSwipe(e: any) {
        this.playerSceneGraphNode.onTouchSwipe(e);
    }

    onTouchDistance(e: any) {
        this.playerSceneGraphNode.onTouchDistance(e);
    }

    onTouchRotate(e: any) {
        this.playerSceneGraphNode.onTouchRotate(e);
    }

    onTouchGesture(e: any) {
        this.playerSceneGraphNode.onTouchGesture(e);
    }

    collectEditableProperties(layout: StackLayout) {}

    getAuthorInterfaceName() {
        return "Simulation";
    }

    createAuthorInterfaceElement(): HTMLElement | undefined {
        return undefined;
    }

    fileDropped(e: DragEvent, files: any[] | undefined) {
        //this.sceneGraph.file_dropped(e, files);
    }

    dragFile(e: DragEvent, files: any[] | undefined) {
        // this.sceneGraph.drag_file(e, files);
    }

    activate(event: InteractiveEvent) {
        this.sceneGraphNode.activate_event(event);
    }

    getActiveScene() {
        return this.sceneGraphNode.getActiveScene();
    }

    getUXScene() {
        return this.sceneGraphNode.getUXScene();
    }

    update(updateContext: UpdateContext) {
        this.sceneGraphNode.update(updateContext);
    }

    onActivity() {}

    file_dropped(e: DragEvent, files?: any[]): void {}

    onCanvasResized(): void {}

    drag_file(e: DragEvent, files?: any[]): void {}
}
