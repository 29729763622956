import { CustomerAccount } from "./customerAccount.js";
import Bowser from "bowser";
import { StackLayout } from "./view/stackLayout.js";
import { ReadonlyProperty } from "./view/propertyEditor.js";
import { Treeview } from "./view/treeview.js";
import { Resources } from "./resources";
import { WebApplication } from "./webApplication";
import { WebApplicationServer } from "./webApplicationServer";

export class WebBrowserClient {
    resources: Resources;
    application: WebApplication;
    json: any;
    server: WebApplicationServer;
    browser: Bowser.Parser.Parser;

    constructor(resources: Resources, application: WebApplication, server: WebApplicationServer) {
        this.resources = resources;
        this.application = application;
        this.server = server;
        this.browser = Bowser.getParser(window.navigator.userAgent);
    }

    stroageItemName() {
        return this.resources.combineJsonResourceNameFromArray([this.application.name, "browser.storage"]);
    }

    initialize() {
        this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());
    }

    saveState() {
        this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }

    collectEditableProperties(layout: StackLayout) {
        let name_property = Object.assign(new ReadonlyProperty(), {
            name: "name",
            getValue: () => {
                return this.browser.getBrowserName();
            },
        });
        layout.addAsTableRow(name_property.getEditorElements());

        let version_property = Object.assign(new ReadonlyProperty(), {
            name: "version",
            getValue: () => {
                return this.browser.getBrowserVersion();
            },
        });
        layout.addAsTableRow(version_property.getEditorElements());
    }

    getAuthorInterfaceName() {
        return "Browser";
    }

    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }

    addAuthorInterfaceElementToTreeview(treeview: Treeview) {
        let elm = this.createAuthorInterfaceElement();
        treeview.addItem(this.getAuthorInterfaceName(), elm, true);
    }
}
