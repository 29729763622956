let c2 = require("c2.js");
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import { RectangleGeometry } from "../geometry/RectangleGeometry.js";
import { ButtonUserInterfaceElement } from "./user_interface_elements/ButtonUserInterfaceElement.ts";
import { TextboxUserInterfaceElement } from "./user_interface_elements/TextboxUserInterfaceElement.ts";
import { TextareaUserInterfaceElement } from "./user_interface_elements/TextareaUserInterfaceElement.ts";
import { LableUserInterfaceElement } from "./user_interface_elements/LableUserInterfaceElement.ts";
import { BreakUserInterfaceElement } from "./user_interface_elements/BreakUserInterfaceElement.ts";
import { v4 as uuidv4 } from "uuid";
import { WebApplication } from "../webApplication.js";
import { TextTemplateReplace } from "../textTemplateReplacement.js";
import { UserInterfaceElement } from "./user_interface_elements/UserInterfaceElement";
/**
 * @callback getRelativeToRect_function
 * @returns {c2.Rect}
 */

/**
 *
 */
export class UserInterfaceElements {
    items: UserInterfaceElement[];
    getRelativeToRect;
    boundsRelativeTo: RectangleGeometry | undefined;
    json: any;
    div: HTMLDivElement | undefined;
    application: WebApplication | undefined;

    constructor(getRelativeToRect: any, json: any, application?: WebApplication) {
        this.json = json;
        this.getRelativeToRect = getRelativeToRect;
        this.items = [];
        this.application = application;

        if (json.relative_area_rect) {
            this.boundsRelativeTo = RectangleGeometry.createGeometryFromJson_Relative({
                "geometry.rectangle": json.relative_area_rect,
            });
        }

        json.elements?.forEach((element: any) => {
            if (element.type === "button") {
                this.items.push(new ButtonUserInterfaceElement(this, element));
            } else if (element.type === "textbox") {
                this.items.push(new TextboxUserInterfaceElement(this, element));
            } else if (element.type === "label") {
                this.items.push(new LableUserInterfaceElement(this, element));
            } else if (element.type === "break") {
                this.items.push(new BreakUserInterfaceElement(this, element));
            } else if (element.type === "textarea") {
                this.items.push(new TextareaUserInterfaceElement(this, element));
            }
        });
    }
    /**
     *
     * @returns
     */
    updateDOM() {
        if (this.div === undefined) {
            return;
        }

        this.updateElement();
    }
    /**
     *
     */
    updateElement() {
        if (this.getRelativeToRect) {
            let rect_abs = this.getRelativeToRect();

            let bounds = this.boundsRelativeTo
                ? this.boundsRelativeTo
                : RectangleGeometry.createGeometryFromJson_Relative({
                      "geometry.rectangle": [0, 0, 1, 1],
                  });
            //var rect_abs = rect.get_absolute_rect_shape();
            //var bounds_abs=bounds.get_absolute_rect_shape();

            rect_abs.p.x += rect_abs.w * bounds.shape.p.x;
            rect_abs.p.y += rect_abs.h * bounds.shape.p.y;
            rect_abs.w = rect_abs.w * bounds.shape.w;
            rect_abs.h = rect_abs.h * bounds.shape.h;
            if (this.div) {
                this.div.style.left = `${rect_abs.p.x}px`;
                this.div.style.top = `${rect_abs.p.y}px`;
                this.div.style.width = `${rect_abs.w}px`;
                this.div.style.height = `${rect_abs.h}px`;
            }
        }
    }
    /**
     *
     */
    addToDOM() {
        if (this.div !== undefined) {
            return;
        }
        let id = "UserInterfaceElements" + uuidv4();

        this.div = Object.assign(document.createElement("div"), {
            id: id,
        });
        this.div.style.position = "fixed";

        if (this.json.margin) {
            this.div.style.margin = this.json.margin;
        }
        this.updateElement();

        this.items.forEach((element) => {
            element.addToDOM();
        });

        let viewport = document.getElementById(InteractiveCanvas.viewportId);

        viewport?.parentElement?.insertBefore(this.div, viewport.nextSibling);
    }

    findElementByName(name: string) {
        for (let each in this.items) {
            if (this.items[each].json.name === name) {
                return this.items[each];
            }
        }
        return undefined;
    }

    removeFromDOM() {
        if (this.div) {
            this.div.remove();
            this.div = undefined;
        }
    }

    replace_text_template(template: string) {
        let element_prefix = "ui.element.";
        let value_suffix = ".value";

        if (template.startsWith(element_prefix) && template.endsWith(value_suffix)) {
            let element_name = template.substring(
                element_prefix.length,
                template.length - value_suffix.length,
            );
            let element = this.findElementByName(element_name);
            return element?.value_string() || "";
        }

        return template;
    }
}
