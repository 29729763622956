import { startClient } from "./library_main.js";
import { WebApplication } from "./webApplication.js";
import { LakesideCabinServer } from "./LakesideCabinServer.js";
import { WebApplicationState } from "./WebApplicationState.js";
import { ExternalModules } from "./modules/ExternalModules.js";

import { ChessExternalModule } from "./client_modules/chess.js";
import { SolitaireExternalModule } from "./client_modules/solitaire.js";
import { CardCraftExternalModule } from "./client_modules/card_craft.js";
import { TriviaExternalModule } from "./client_modules/trivia.js";
import { GolfExternalModule } from "./client_modules/golf.js";
import { LoadingExternalModule } from "./client_modules/loading.js";

let versionNumber = "0.7.12";

/**
 *
 */
class LakesideCabinWebApplication extends WebApplication {
    constructor(state: WebApplicationState) {
        super("Lakeside Cabin", state.clientVersionNumber);
    }

    initializeExternalModules(state: WebApplicationState) {
        ExternalModules.modules = [
            new ChessExternalModule(),
            new SolitaireExternalModule(),
            new CardCraftExternalModule(),
            new LoadingExternalModule(),
            new TriviaExternalModule(),
            new GolfExternalModule(),
        ];
    }

    getS3ContentBucketPath() {
        return "lakeside-cabin/";
    }
}
/**
 *
 */
class LakesideCabinWebApplicationServer extends LakesideCabinServer {
    constructor(state: WebApplicationState) {
        super(state.resources, state.application);
    }
}

(async () => {
    let state = new WebApplicationState();
    state.clientVersionNumber = versionNumber;

    let newWebApplication = (withState: WebApplicationState) => {
        return new LakesideCabinWebApplication(withState);
    };

    let newServer = (withState: WebApplicationState) => {
        return new LakesideCabinWebApplicationServer(withState);
    };

    await startClient(state, newWebApplication, newServer);
})();
