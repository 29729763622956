import { WebApplicationState } from "../../../../WebApplicationState";
import { showLoadingPage } from "../../loading/loading";

/**
 * Shows the pending page, shown when the user is waiting for the phone to authenticate.
 * This is where the QR code is shown.
 * @param state the state of the application.
 */
export async function showPendingPage(state: WebApplicationState) {
    const { challenge } = await state.auth.generateCodeVerifierAndChallenge();
    let redirectURL: string | undefined = undefined;
    let connectionID: string | undefined = undefined;
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("redirect")) {
        redirectURL = searchParams.get("redirect") || undefined;
    }

    // The redirect url is encoded, so we need to decode it.
    if (redirectURL) {
        redirectURL = decodeURIComponent(redirectURL);
    }

    state.auth.createLoginSession(redirectURL);

    if (searchParams.has("id")) {
        // At this point, we know the connection id is undefined, we are waiting for it to be defined
        connectionID = searchParams.get("id") as string;
    }
    connectionID = decodeURIComponent(connectionID as string);

    // Wait for the connection ID from the WS while it connects.
    if (!!connectionID) {
        // Must be double exclamation points to convert to boolean.
        showLoadingPage(state);
        console.log("waiting for connection id");
        connectionID = await new Promise<string>((resolve) => {
            console.log("Waiting for connection ID...");
            const interval = setInterval(() => {
                if (state.auth.connectionID) {
                    clearInterval(interval);
                    resolve(state.auth.connectionID);
                }
            }, 100);
        });
    }

    console.log("connection id: ", connectionID);
    // Store a random state for the device to protect against CSRF:
    // https://auth0.com/docs/secure/attack-protection/state-parameters
    // In our case, we use the WS ConnectionID, generated by AWS.
    localStorage.setItem("deviceID", connectionID);

    state.router.addPageToViewport(
        `
        <div class="login-page">
            <h1>Sign in to Lakeside Cabin</h1>
            <h3>Scan the QR code below to get started.</h3>
            <canvas id="qr-code"></canvas>
            <button id="cancel-button">Cancel</button> 
        </div>
    `,
        "login-modal",
    );

    const cancelButton = document.getElementById("cancel-button");
    cancelButton?.focus();

    /**
     * Handles when the cancel button is clicked.
     * Returns to the login page.
     */
    const cancelHandler = () => {
        console.log("Cancel login session");
        state.auth.ws?.close(); // Close the WS connection.
        window.location.href = `/login?redirect=${redirectURL}`;
        removeEventListeners();
    };

    /**
     * Removes the event listeners for the pending page.
     */
    function removeEventListeners() {
        cancelButton?.removeEventListener("click", cancelHandler);
        removeEventListener("keydown", cancelHandler);
    }

    cancelButton?.addEventListener("click", cancelHandler);
    state.auth.showQRCode(connectionID, challenge);
}
