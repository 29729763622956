import { StackLayout } from "../../view/stackLayout";
import { Treeview } from "../../view/treeview";
import { NullConnectedStems } from "./NullConnectedStems";

import { Audio } from "./webAudio";
import { PlaylistManager } from "./playlistAPI/playlistManager.ts";
import { Stem } from "./stem";
import { Resources } from "../../resources";

export class NullWebAudio implements Audio {
    connectedStems: NullConnectedStems;
    /**
     * stores a reference to the playlist manager object
     */
    playlistManager: PlaylistManager;

    resources: Resources;
    account = null;
    audioContext: AudioContext;
    layers = new Map<string, GainNode>();
    constructor() {
        this.connectedStems = new NullConnectedStems();
        //this.playlistManager = new PlaylistManager("playlists", getRadioSettings)
    }

    storageItemName() {
        return "No StorageItem";
    }
    addAuthorInterfaceElementToTreeview(treeview: Treeview) {}

    connectNodeToLayer(layer: string, node: GainNode) {}

    initialize() {
        console.log("No Audio");
    }

    tryStartAudio() {
        return true;
    }

    uninitialize() {}

    start() {}

    deactivate() {}

    reactivate() {}

    stroageItemName() {}

    saveState() {}

    shutdown() {}

    playSoundEffects(stems: Stem[]) {}

    startAudioOnGesture() {}

    collectEditableProperties(layout: StackLayout) {}

    getAuthorInterfaceName() {
        return "audio";
    }
    createAuthorInterfaceElement() {
        let layout = new StackLayout();
        this.collectEditableProperties(layout);
        return layout.element;
    }
}
