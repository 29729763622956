import { PlaylistManager } from "../../audio_visual/audio/playlistAPI/playlistManager.ts";
import {
    toggleRadio,
    resolveRadioPlaylist,
    getDefaultRadioGain,
} from "../../audio_visual/audio/playlistAPI/Radio";
import { boundGain } from "../../audio_visual/audio/playlistAPI/utils";
import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";
import { RadioActionValue } from "./types";

export class ToggleRadio extends Action {
    value: RadioActionValue;
    value_context;

    constructor(verb: string, name?: string, json?: any) {
        super(verb, name);
        this.value = json["value"];
        this.value_context = json["value_context"];
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("ToggleRadio");
        const { radio: radioName, gain } = this.value;
        const playlistManager = interactive?.scene.sceneGraph.simulation.audio.playlistManager;
        playlistManager?.setPlaylistState({
            id: resolveRadioPlaylist(radioName).name,
            gain: boundGain(gain ?? getDefaultRadioGain(radioName)),
            isActive: toggleRadio(radioName),
        });
    }
}
