import { VisualElements } from "./VisualElements";
import { Actions } from "../../sceneGraph/actions/actions";
import { WebApplication } from "../../webApplication";
import { onDrawingFunction, OnDrawingParams } from "./OnDrawingParams";
import { GettingReadyCallbackCollector } from "../../sceneGraph/GettingReadyCallbackCollector";
import { InteractiveCanvas } from "../interactive_canvas";
import {
    ResourceCanvasElement,
    ResourceCanvasElementInterface,
} from "./resourceCanvasElements/ResourceCanvasElement";
import { WebApplicationState } from "../../WebApplicationState";
import { UpdateContext } from "../../update";
import { SceneObjectInterface } from "../../sceneGraph/SceneObjectInterface";
import { ResourceInterface, ResourceTypeInterface } from "./resources/resourceInterface";
import IStoppableCanvasElement from "./interfaces/IStoppableCanvasElement";
let c2 = require("c2.js");

export class VisualElement {
    obj: SceneObjectInterface;
    json: any;
    resource_canvas_elements: ResourceCanvasElementInterface[] = [];
    active_element?: ResourceCanvasElementInterface;
    onDisplayed: (resource: ResourceInterface, success: boolean) => void;
    isHidden: boolean;
    isStopped = true;
    onDrawing: onDrawingFunction;
    callback_collector?: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<any>;
    resource_path: string;
    drawOrder: number;

    constructor(obj: SceneObjectInterface, json: any, resource_path: string) {
        this.obj = obj;
        this.json = json;
        this.resource_path = resource_path;
        this.addFromJson();
    }

    get_first_file_info() {
        if (this.active_element && this.active_element.url_file_info) {
            return this.active_element.url_file_info;
        }

        for (const each of this.resource_canvas_elements) {
            if (each.url_file_info) {
                return each.url_file_info;
            }
        }
    }

    on_display_timeout() {
        if (this.active_element) {
            this.onResourceCanvasElementError(this.active_element);
        }
    }

    resource_displaying(resource: ResourceInterface, success: boolean) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) === true) {
                this.visualElements.startDisplayTimeout(this);
            }
        }
    }

    resource_displayed(resource: ResourceInterface, success: boolean) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }
        if (this.application.getSetting(WebApplication.isVideoPlayingTimeoutSettingName)) {
            if (this.visualElements.isAdded(this) === true) {
                this.visualElements.cancelDisplayTimeout(this);
            }
        }

        this.onDisplayed?.(resource, success);
    }

    get resources() {
        return this.obj.resources;
    }

    get icanvas() {
        return this.obj.icanvas;
    }

    get webApplicationState() {
        return this.icanvas.state;
    }

    get geometry() {
        return this.obj.geometry;
    }

    get scene() {
        return this.obj.scene;
    }

    get application() {
        return this.scene.application;
    }

    get visualElements() {
        return this.obj.visualElements;
    }

    get server_file_cache() {
        return this.obj.scene.server_file_cache;
    }

    get visual_resource() {
        return this.active_element?.visual_resource;
    }

    setHidden(value: boolean) {
        this.isHidden = value;
        this.active_element?.setHidden(value);
    }

    setDrawOrder(value: number) {
        this.drawOrder = value;
        this.active_element?.setDrawOrder(value);
    }

    drawFrame(icanvas: InteractiveCanvas) {
        if (this.onDrawing) {
            let params = new OnDrawingParams();
            this.onDrawing(params);
            if (params.isCancel) {
                return;
            }
        }

        let background_color = this.json["background.color"];

        if (background_color) {
            let shape = this.geometry.get_absolute_rect_shape();
            icanvas.draw_rect_with_border(
                shape,
                [background_color[0], background_color[1], background_color[2]],
                background_color[3],
            );
        }

        let text = this.json["text"];

        if (text) {
            let shape = this.geometry.get_absolute_rect_shape();
            let pos = new c2.Point(shape.p.x + 4, shape.p.y + 24);

            if (text.includes("{{selectionMode.help}}")) {
                text = text.replace(
                    "{{selectionMode.help}}",
                    this.scene.simulation.selectionMode.helpText(),
                );
            }

            icanvas.draw_text_with_newlines(text, pos, 20, this.json["text.color"]);
        }
    }

    /**
     *
     * @param {import('./resources/resourceInterface').ResourceTypeInterface} type
     * @param {string} json_property
     */
    createAndAddVisualResourceFromJson(type: ResourceTypeInterface, json_property: string) {
        let resource = type.createResourceFromJson(
            this.json,
            json_property,
            this.resource_path,
            this.json.resourcePath2,
            this.application,
        );
        if (!resource) {
            console.error("resource Failed", this.json, this.resource_path);
            return;
        }

        resource.onRunAction = (action) => {
            let actions = new Actions();
            actions.parseJson(action, this.application);
            console.log(this.obj);
            actions.runActions(this.obj);
            console.log(this.obj);
        };

        let element = resource.createResourceCanvasElement(this);

        this.resource_canvas_elements.push(element);
    }
    /**
     *
     */
    addFromJson() {
        if (this.json === undefined) {
            return;
        }
        // iterate through every property on the json object assocated with this Visual Element
        for (const each_property in this.json) {
            // iterate through the the visualResourceTypes [audio, video, image]
            for (const each of VisualElements.visualResourceTypes) {
                if (each?.canCreateResourceFromJson(this.json, each_property, this.application)) {
                    // console.log("can create resource from ", each)
                    // console.log("can create resource from ", each_property)

                    this.createAndAddVisualResourceFromJson(each, each_property);
                }
            }
            if (each_property === "drawOrder") {
                this.setDrawOrder(this.json[each_property]);
            }
        }
    }

    onResourceCanvasElementError(resource_canvas_element: ResourceCanvasElementInterface) {
        console.warn("visual element on error: " + resource_canvas_element.toSourceURLNameExt());
    }

    getFirstReadyResourceCanvasElement() {
        for (const each of this.resource_canvas_elements) {
            if (each.isReady && !each.isError) {
                return each;
            }
        }
        return undefined;
    }

    startVisualContentGetReady(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        for (let index = 0; index < this.resource_canvas_elements.length; index++) {
            const each_element = this.resource_canvas_elements[index];

            each_element.start_loading(gettingReadyCallbackCollector);
            break;
        }
    }

    start_active_element() {
        if (this.active_element) {
            this.active_element.onDrawing = this.active_element_on_drawing.bind(this);
            this.active_element.setHidden?.(this.isHidden);
            if (this.drawOrder !== undefined) {
                this.active_element.setDrawOrder(this.drawOrder);
            }
            this.active_element.start();
        }
    }

    start() {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }

        this.visualElements.add(this);
        this.isStopped = false;

        if (this.active_element) {
            this.start_active_element();
            return;
        }

        let first_ready = this.getFirstReadyResourceCanvasElement();
        if (first_ready) {
            this.active_element = first_ready;
            this.start_active_element();

            return;
        }

        if (!this.callback_collector) {
            this.callback_collector = new GettingReadyCallbackCollector();

            this.startVisualContentGetReady(this.callback_collector);
            this.callback_collector_promise = this.callback_collector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promise = undefined;
                    this.callback_collector = undefined;
                    if (this.visualElements?.isAdded(this) === true) {
                        let first_ready = this.getFirstReadyResourceCanvasElement();
                        if (first_ready) {
                            this.active_element = first_ready;
                            this.start_active_element();
                            this.icanvas.try_invalidated_draw();
                        }
                    }
                });
        }
    }

    stop(next_resource_canvas_element?: IStoppableCanvasElement) {
        if (!this.visualElements) {
            console.warn("missing visual elements");
            return;
        }

        this.visualElements.remove(this);

        this.isStopped = true;
        this.active_element?.stop(next_resource_canvas_element);
    }

    firstResourceCanvasElement() {
        return this.resource_canvas_elements[0];
    }

    active_element_on_drawing(params: OnDrawingParams) {
        this.onDrawing?.(params);
    }

    // collectCanvasElements(result) {
    //     this.active_element?.collectCanvasElements(result);
    // }

    isLoading() {
        if (this.active_element) {
            return this.active_element.isLoading();
        }

        return false;
    }

    isReady() {
        if (this.icanvas.State.msePlayer) {
            return false;
        }
        return this.active_element !== undefined;
    }

    isReadyOrEmpty() {
        return this.isReady() || this.isEmpty();
    }

    isEmpty() {
        return this.resource_canvas_elements.length === 0;
    }

    update(update_context: UpdateContext) {
        this.active_element?.update?.(update_context);
    }
}
