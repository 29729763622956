import { FileResourceRequest } from "../../../resources/FileResourceRequest";
import { AudioPlaylist, Playlist } from "./Playlist";
import { AudioPlaylistConfig, PlaylistConfig } from "./PlaylistConfig";
import { Observable, PlaylistEvent, PlaylistItem, PlaylistObserver, PlaylistState } from "./types";
import { createAudioPlaylistItemFromStem } from "./utils";

/**
 * The `AudioPlaylistManager` class serves as the central component for managing
 * multiple audio playlists. It implements both the `PlaylistManager` and
 * `Observable<PlaylistObserver>` interfaces, enabling playlist operations
 * while providing mechanisms for observer-based notifications to subscribers
 *
 * Changes to the currently playing playlist should occur through the manager rather
 * than through the lower-level `PlaylistPlayer` class
 */

export interface PlaylistStore {
    size: number;
    keys: string[];
    add(id: string, playlistData: PlaylistConfig): void;
    get(name: string): Playlist | null;
    contains(id: string): boolean;
    loadPlaylists(url?: string): Promise<void>;
    setPlaylistState(state: PlaylistState): void;
}

export interface PlaylistManager extends PlaylistStore, Observable<PlaylistObserver> {
    setActive(id: string, isActive: boolean): void;
    updateGain(id: string, gain: number): void;
}

export class AudioPlaylistManager implements PlaylistManager {
    private playlists: Map<string, Playlist> = new Map();
    private observers: PlaylistObserver[] = [];
    get keys() {
        const keys = [];
        for (const key of this.playlists.keys()) {
            keys.push(key);
        }
        return keys;
    }

    get size(): number {
        return this.playlists.size;
    }

    async loadPlaylists(url: string = "/assets/data/audio.playlists.json"): Promise<void> {
        const response = await fetch(url); // all playlists
        if (!response.ok) {
            console.error(response);
        }
        const data = await response.json();

        data.playlists.forEach((playlistData: any) => {
            this.add(playlistData.name, new AudioPlaylistConfig(playlistData));
        });
    }

    add(id: string, playlistData: PlaylistConfig): void {
        const playlist: Playlist = new AudioPlaylist(id, playlistData.isLooping); // @TODO move to factory?
        playlistData.stems.forEach((stem) => {
            const resourceRequest = new FileResourceRequest(
                playlistData.getParentResourcePath(),
                stem.audioSrc,
                ".mp3",
            );
            const item: PlaylistItem = createAudioPlaylistItemFromStem(resourceRequest, stem);
            playlist.addItem(item);
        });
        this.playlists.set(id, playlist);
    }

    set(id: string, playlist: Playlist) {
        this.playlists.set(id, playlist);
    }
    /**
     *
     * @param name
     * @throws Error
     */
    get(name: string): Playlist | null {
        const playlist = this.playlists.get(name);
        if (!playlist) {
            return null;
            throw new Error(`get called with no playlist ${name}`);
        }
        return playlist;
    }

    setActive(id: string, isActive: boolean) {
        const playlist = this.get(id);
        if (!playlist) {
            return;
        }
        playlist.isActive = isActive;
        this.set(id, playlist);
        if (isActive) {
            this.notify({ type: "play", playlistId: id, data: playlist });
        } else {
            this.notify({ type: "stop", playlistId: id, data: playlist });
        }
    }

    updateGain(id: string, gain: number) {
        const playlist = this.get(id);
        if (!playlist) {
            return;
        }
        if (playlist.gain === gain) {
            return;
        }
        playlist.gain = gain;
        this.notify({ type: "update", playlistId: id, data: playlist });
    }

    contains(id: string): boolean {
        return this.playlists.get(id) != null;
    }

    registerObserver(observer: PlaylistObserver) {
        this.observers.push(observer);
    }

    unregisterObserver(observer: PlaylistObserver) {
        // issue with mutable?
        this.observers = this.observers.filter((obs) => obs !== observer);
    }

    setPlaylistState(playlistState: PlaylistState) {
        this.updateGain(playlistState.id, playlistState.gain);
        this.setActive(playlistState.id, playlistState.isActive);
    }

    private notify(event: PlaylistEvent) {
        this.observers.forEach((obs) => obs.onPlaylistChanged(event));
    }
}
