import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "../../sceneGraph/GettingReadyCallbackCollector";
import { SceneObjectInterface } from "../../sceneGraph/SceneObjectInterface";
import { UpdateContext } from "../../update";
import { AmbianceContext } from "../audio/AmbianceContext";
import { AudioAmbiance } from "../audio/audioAmbiance";
import { AudioListenerScope } from "../audio/AudioListenerScope";
import { AudioVisualPlaybackQueue } from "./AudioVisualPlaybackQueue";
import IStoppableCanvasElement from "./interfaces/IStoppableCanvasElement";
import { VideoFileResource } from "./resources/VideoFileResource";
import { VisualElement } from "./visualElement";
import { VisualElements } from "./VisualElements";

/**
 * an audio visual item playing or waiting in a AudioVisualPlaybackQueue
 */
export class AudioVisualPlaybackQueueItem {
    name?: string;
    type?: string;
    audio_stems: any[] = [];
    /**
     * the one video stem from the stems collection that is selected to start
     */
    video_stem: any;
    is_looping = true;
    is_play_immediate = true;
    started?: StartedAudioVisualPlaybackQueueItem;
    loading: LoadingAudioVisualPlaybackQueueItem;
    stopped: StoppedAudioVisualPlaybackQueueItem;

    constructor(stems: any[]) {
        for (const each of stems || []) {
            if (VisualElements.VideoVisualResourceType?.canCreateResourceFromJsonObject(each)) {
                if (!this.video_stem) {
                    this.video_stem = each;
                }
            } else if (each.audioSrc) {
                this.audio_stems.push(each);
            } else if (each.audioAmbiance) {
                this.audio_stems.push(each);
            }
        }
    }

    get resourceCanvasElement() {
        return (
            this.loading.visual_element?.active_element ||
            this.loading.visual_element?.getFirstReadyResourceCanvasElement()
        );
    }

    get videoStem() {
        return this.video_stem;
    }

    get isStarted() {
        return this.started !== undefined;
    }

    get isStopped() {
        return this.stopped !== undefined;
    }

    toString() {
        return `${this.video_stem?.video}`;
    }

    getVideoStemFileInfo(scene_object: SceneObjectInterface) {
        if (!this.video_stem) return undefined;

        let fileinfo = scene_object.application.server_file_cache.findFileWithResourcePath(
            this.video_stem.video,
            scene_object.getResourcePath(),
            this.video_stem.resourcePath2,
            VideoFileResource.VideoFileExtension,
            VideoFileResource.VideoCategoryPath,
        );

        return fileinfo;
    }

    start(playbackQueue: AudioVisualPlaybackQueue) {
        if (this.started) {
            console.warn("AudioVisualPlaybackQueueItem::start already started");
            return;
        }
        if (!this.loading) {
            console.warn("AudioVisualPlaybackQueueItem::start not yet loading");
            return;
        }
        let starting = new StartedAudioVisualPlaybackQueueItem();

        if (this.loading.visual_element) {
            starting.visual_element = this.loading.visual_element;
            starting.visual_element.start();
        }
        if (this.loading.audio_element) {
            starting.audio_element = this.loading.audio_element;
            playbackQueue.sceneObject.scene_graph_node?.listener.addAudioAmbiance(
                starting.audio_element,
            );
        }

        //this.loading = undefined;
        starting.playbackQueue = playbackQueue;
        this.started = starting;
    }

    stop(obj: SceneObjectInterface, next_resource_canvas_element?: IStoppableCanvasElement) {
        if (!this.started) {
            console.warn("AudioVisualPlaybackQueueItem::stop not yet started");
            return;
        }

        if (this.started.visual_element) {
            this.started.visual_element.stop(next_resource_canvas_element);
        }
        if (this.started.audio_element) {
            obj.scene_graph_node?.listener.removeAudioAmbiance(this.started.audio_element);
        }
        this.started = undefined;
        this.stopped = new StoppedAudioVisualPlaybackQueueItem();
    }

    onPlayedOnce() {
        if (!this.started) {
            console.warn("AudioVisualPlaybackQueueItem::onPlayedOnce started is undefined");
        }
        if (this.stopped) {
            console.warn("AudioVisualPlaybackQueueItem::onPlayedOnce already stopped");
        }

        this.started?.playbackQueue?.beginPlayingItemAfter(this);
    }

    start_loading(
        obj: SceneObjectInterface,
        gettingReadyCallbackCollector: GettingReadyCallbackCollector,
    ) {
        if (this.started) {
            //console.warn("AudioVisualPlaybackQueueItem::start_loading already started");
            return;
        }
        if (this.loading) {
            if (this.loading.loadingPromise) {
                const callback = new GettingReadyCallback();
                callback.addPromise(this.loading.loadingPromise);
                gettingReadyCallbackCollector.add(callback);
            }

            //console.warn("AudioVisualPlaybackQueueItem::start_loading already loading");
            return;
        }

        let load = new LoadingAudioVisualPlaybackQueueItem();

        if (this.video_stem) {
            this.startLoadingVisualElement(
                obj,
                this.video_stem,
                load,
                gettingReadyCallbackCollector,
            );
        }

        for (const each of this.audio_stems) {
            if (each.audioSrc) {
                if (!load.audio_element) {
                    load.audio_element = this.newAudioAmbiance(obj, {});
                }
                load.audio_element.addStemFromJson(each);
            } else if (each.audioAmbiance) {
                load.audio_element = this.newAudioAmbiance(obj, each.audioAmbiance);
            }
        }

        load.loadingPromise = gettingReadyCallbackCollector.newWaitPromiseForAllCallbacks();
        this.loading = load;
    }

    startLoadingVisualElement(
        obj: SceneObjectInterface,
        each: any,
        load: LoadingAudioVisualPlaybackQueueItem,
        gettingReadyCallbackCollector: GettingReadyCallbackCollector,
    ) {
        each["video.play_immediate"] = this.is_play_immediate;

        if (this.is_looping === false) {
            each["video.loop"] = false;
            let self = this;
            each["video.event.playedOnce"] = {
                function: () => {
                    self.onPlayedOnce();
                },
            };
        }

        load.visual_element = new VisualElement(obj, each, obj.getResourcePath());
        load.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
    }

    newAudioAmbiance(obj: SceneObjectInterface, json: any) {
        let audioContext: AmbianceContext;
        if (!obj.toAudioContext) {
            let scope = AudioListenerScope.fromScenePath(obj.toScenePath(), obj, obj.sceneGraph);
            audioContext = new AmbianceContext(scope);
        } else {
            audioContext = obj.toAudioContext();
        }

        return new AudioAmbiance(audioContext, json, () => obj.getResourcePath());
    }
}

/**
 * a container for loading elements of an audio visual item in a queue for playback
 */
export class LoadingAudioVisualPlaybackQueueItem {
    visual_element?: VisualElement;
    audio_element?: AudioAmbiance;
    loadingPromise?: Promise<any>;
}

/**
 * a container for started elements of an audio visual item in a queue for playback
 */
export class StartedAudioVisualPlaybackQueueItem {
    visual_element?: VisualElement;
    audio_element?: AudioAmbiance;
    playbackQueue?: AudioVisualPlaybackQueue;
}

/**
 * a container for stopped elements of an audio visual item in a queue for playback
 */
export class StoppedAudioVisualPlaybackQueueItem {}
