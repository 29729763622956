import { Playlist } from "./Playlist";
import { PlaylistEvent, PlaylistItem, PlaylistObserver } from "./types";
import { boundGain, setGain } from "./utils";

export interface PlaylistPlayer {
    play: (playlist: Playlist) => void;
    stop: (playlist: Playlist) => void;
    masterVolume: GainNode;
}

/**
 * The WebAudioPlaylistPlayer class manages the playback of audio playlists using the Web Audio API.
 * It integrates the creation of audio nodes, playlist management, and volume control for each
 * playlist, allowing for multiple playlists to be played simultaneously while sharing a common
 * volume node.
 */
export class WebAudioPlaylistPlayer implements PlaylistPlayer, PlaylistObserver {
    masterVolume: GainNode;
    private audioContext: AudioContext; // @TODO: replace with appropriate wrapper class to limit funcitonality
    private loader: AudioBufferLoader;
    private connectedNodes: Map<string, WebAudioPlaylistNode> = new Map();

    /**
     *
     * @param audioContext: The AudioContext instance used to manage
     * the audio processing graph and generate audio nodes.
     * @param outputStage: A GainNode that the master volume will be connected to,
     * representing the output stage of the audio graph
     */
    constructor(audioContext: AudioContext, outputStage: GainNode) {
        try {
            this.audioContext = audioContext;
            this.loader = new AudioBufferLoader(this.audioContext);
            this.masterVolume = audioContext.createGain();
            this.setMasterGain();
            // connect gain node to the correct output stage
            this.masterVolume.connect(outputStage);
        } catch (err) {
            console.error("Playlist Player failed to initialize:", err);
        }
    }

    onPlaylistChanged(event: PlaylistEvent): void {
        switch (event.type) {
            case "play":
                this.play(event.data);
                break;
            case "update":
                this.setPlaylistGain(event.playlistId, event.data.gain);
                break;
            case "stop":
                const playlist = this.connectedNodes.get(event.playlistId);
                if (!playlist) {
                    return;
                }
                this.stop(playlist.playlist);
                break;
            default:
                break;
        }
    }

    play(playlist: Playlist): void {
        if (!this.connectedNodes.has(playlist.id)) {
            const playlistNode = this.createNewNode(playlist);
            this.connectedNodes.set(playlist.id, playlistNode);
            playlistNode?.play();
        }
    }

    stop(playlist: Playlist) {
        const id = playlist.id;
        if (this.connectedNodes.has(id)) {
            const playlistNode = this.connectedNodes.get(id);
            playlistNode?.remove();
        }
        // removes the connected node
        this.connectedNodes.delete(id);
    }

    /**
     *
     * @param gain defaults to 1.0 (maximum volume)
     */
    setMasterGain(gain: number = 1.0): void {
        // set the gain value of the master gain node
        setGain(this.audioContext, this.masterVolume, gain);
    }
    setPlaylistGain(playlistId: string, gain: number): void {
        const playlistNode = this.connectedNodes.get(playlistId);
        playlistNode?.setGain(gain);
        // set the gain value of the playlist node
    }
    private createNewNode(playlist: Playlist): WebAudioPlaylistNode {
        return new WebAudioPlaylistNode(
            this.audioContext,
            playlist,
            this.loader,
            this.masterVolume,
        );
    }
}

/**
 * The `WebAudioPlaylistNode` class represents
 * a single "playlist" and controls the playback of specific
 * audio sources within a playlist
 */
class WebAudioPlaylistNode {
    playlist: Playlist;
    private gainNode: GainNode;
    private audioContext: AudioContext;
    private loader: AudioBufferLoader;
    private currentSource: AudioBufferSourceNode | null = null;
    private isPlaying = false;
    private activeTrackGain = 1;

    constructor(
        audioContext: AudioContext,
        playlist: Playlist,
        loader: AudioBufferLoader,
        output: GainNode,
    ) {
        this.audioContext = audioContext;
        this.playlist = playlist;
        this.loader = loader;
        this.gainNode = audioContext.createGain();
        setGain(this.audioContext, this.gainNode, playlist.gain);
        this.gainNode.connect(output);
    }

    async play(): Promise<void> {
        const activeItem = this.playlist.getActiveItem();
        if (!activeItem) {
            console.log(`no activeItem found for playlist ${this.playlist.id}`);
            return;
        }
        try {
            this.isPlaying = true;
            this.currentSource = await this.loader.load(activeItem);

            this.setGain(this.playlist.gain, activeItem.gain);

            this.currentSource.onended = () => {
                console.log(`Audio ${activeItem.url} finished playing. Cleaning up...`);
                this.onTrackEnd();
            };

            this.currentSource.connect(this.gainNode);
            this.currentSource.start();
            console.log(`Audio ${activeItem.url} playing...`);
        } catch (err) {
            console.error(`Audio Error: ${err}`);
            this.playNext();
        }
    }

    setGain(playlistGain: number, activeTrackGain = this.activeTrackGain) {
        this.playlist.gain = boundGain(playlistGain);
        this.activeTrackGain = boundGain(activeTrackGain);
        const finalgain = this.playlist.gain * this.activeTrackGain;
        setGain(this.audioContext, this.gainNode, finalgain);
    }

    remove() {
        this.stopPlayback();
        this.gainNode.disconnect();
    }

    /*
     * Disconnect source from audio graph and cleans up
     */
    private stopPlayback() {
        if (this.isPlaying) {
            this.isPlaying = false;
        }

        if (this.currentSource) {
            this.currentSource.stop();
            this.currentSource.disconnect();
            this.currentSource.onended = null;
            this.currentSource = null;
        }
    }

    private playNext() {
        this.playlist.next();
        this.play();
    }

    private onTrackEnd() {
        if (this.isPlaying) {
            this.stopPlayback();
            this.playNext();
        }
    }
}

/**
 * The `AudioBufferLoader` class provides methods for fetching
 * and decoding audio data to be compatible with the WebAudio API
 * and TV playback with MSE
 *
 * For compatibility with Sony TVs more appropriate methods for playing
 * audio Tracks using the Web Audio API cannot be used as they create
 * and play from Audio HTML Elements which are incompatible with MSE
 * playback on SONY TV useragents
 */
class AudioBufferLoader {
    private audioContext: AudioContext;

    constructor(audioContext: AudioContext) {
        this.audioContext = audioContext;
    }

    async load(item: PlaylistItem): Promise<AudioBufferSourceNode> {
        const sourceBuff = this.audioContext.createBufferSource();
        sourceBuff.buffer = await this.fetchAndDecodeAudio(item.url);
        return sourceBuff;
    }

    private async fetchAndDecodeAudio(url: string): Promise<AudioBuffer> {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        return this.audioContext.decodeAudioData(arrayBuffer);
    }
}
