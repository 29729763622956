import { v4 as uuidv4 } from "uuid";
import { UserInterfaceElement } from "./UserInterfaceElement";

export class TextareaUserInterfaceElement extends UserInterfaceElement {
    element: HTMLTextAreaElement;

    constructor(elements: any, json: any) {
        super(elements, json);
    }

    addToDOM(): void {
        const id = uuidv4();

        this.element = Object.assign(document.createElement("textarea"), {
            id: id,
        }) as HTMLTextAreaElement;

        if (this.json.margin) {
            this.element.style.margin = this.json.margin;
        }
        this.element.rows = 16;
        this.element.cols = 27;
        this.elements.div.appendChild(this.element);
    }

    value_string(): string {
        return this.element.value;
    }
}
