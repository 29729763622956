// @ts-check

import { RectangleGeometry } from "../../../geometry/RectangleGeometry";
import { GettingReadyCallbackCollector } from "../../../sceneGraph/GettingReadyCallbackCollector";
import { SceneObjectInterface } from "../../../sceneGraph/SceneObjectInterface";
import { SceneGraphAudioVisualState } from "../../../sceneGraph/audio-visual-script/SceneGraphAudioVisualState";
import { UpdateContext } from "../../../update";
import { AudioVisualPlaybackQueueItem } from "../AudioVisualPlaybackQueueItem";
import { onDrawingFunction } from "../OnDrawingParams";
import { AudioVisualScriptResource } from "../resources/AudioVisualScriptResource";
import { ResourceInterface } from "../resources/resourceInterface";
import { VisualElement } from "../visualElement";
import { AudioVisualPlaybackQueue } from "./../AudioVisualPlaybackQueue";
import { ResourceCanvasElement, ResourceCanvasElementInterface } from "./ResourceCanvasElement";

export class AudioVisualScriptResourceCanvasElement {
    static nextState(obj: SceneObjectInterface | undefined) {
        let av_script = obj?.visual_element?.active_element;
        if (!(av_script instanceof AudioVisualScriptResourceCanvasElement)) {
            return;
        }

        let next = av_script.av_script.state_set.getNextState(av_script.active_state);

        if (!next) {
            return;
        }

        av_script.playback?.clearQueue();

        if (av_script.playback) {
            let previous = av_script.active_state;
            av_script.active_state = next;
            av_script.active_state?.loadPlayback(av_script.playback, previous);
            let gettingReadyCallbackCollector = new GettingReadyCallbackCollector();

            av_script.active_state?.start();
            av_script.playback.beginPlayingStartOfQueue(gettingReadyCallbackCollector);
        } else {
            console.error("Playback queue is undefined");
        }
    }
    vis: VisualElement;
    visual_resource: AudioVisualScriptResource;
    onDrawing: onDrawingFunction;
    isReady = false;
    isError: boolean;
    playback: AudioVisualPlaybackQueue | undefined;

    active_state?: SceneGraphAudioVisualState;
    //active_script;

    constructor(vis: VisualElement, visual_resource: AudioVisualScriptResource) {
        this.vis = vis;
        this.visual_resource = visual_resource;
        this.playback = new AudioVisualPlaybackQueue(this.vis.obj);

        if (visual_resource.default_state) {
            this.active_state = visual_resource.state_set.getNewStateObjectByName(
                visual_resource.default_state,
            );
        } else {
            this.active_state = visual_resource.state_set.getDefaultState();
        }

        if (this.active_state) {
            this.active_state.loadPlayback(this.playback);
        }
    }
    get av_script() {
        let r = this.visual_resource;
        return r;
    }
    get playbackQueue() {
        return this.playback;
    }

    // static nextPlaybackQueueItem(sceneObject: SceneObjectInterface) {
    //     const av_script = sceneObject.visual_element?.active_element;
    //     if (!(av_script instanceof AudioVisualScriptResourceCanvasElement)) {
    //         return;
    //     }
    //     av_script.playback.beginPlayingNextInQueue()
    // }

    start_loading(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        if (this.playback === undefined) {
            this.playback = new AudioVisualPlaybackQueue(this.vis.obj);

            if (this.active_state) {
                this.active_state.loadPlayback(this.playback);
            }
        }
        this.playback.beginLoadingStartOfQueue(gettingReadyCallbackCollector);
        this.isReady = true;
    }

    start() {
        if (this.playback === undefined) {
            this.playback = new AudioVisualPlaybackQueue(this.vis.obj);

            if (this.active_state) {
                this.active_state.loadPlayback(this.playback);
            }
        }
        this.active_state?.start();
        this.playback.beginPlayingStartOfLoadedQueue();
    }
    isPauseMSEOnStart() {
        return false;
    }
    stop(next_resource_canvas_element?: ResourceCanvasElement) {
        this.stopAndDisposePlaybackQueue(next_resource_canvas_element);
    }

    stopAndDisposePlaybackQueue(
        next_resource_canvas_element: ResourceCanvasElementInterface | undefined,
    ) {
        this.playback?.stop(next_resource_canvas_element);
        this.playback = undefined;
    }

    setHidden(value: boolean) {}

    setDrawOrder(value: number) {}

    isLoading() {
        return false;
    }

    toSourceURLNameExt() {
        return undefined;
    }

    get geometry() {
        if (this.vis.obj.visual_geometry) {
            return this.vis.obj.visual_geometry;
        }
        if (this.vis.obj.geometry) {
            return this.vis.obj.geometry;
        }
        if (this.visual_resource) {
            let size = this.visual_resource.pixel_size();
            let result = new RectangleGeometry();
            result.initialize(0, 0, size[0], size[1]);
            return result;
        }
        let result = new RectangleGeometry();
        result.initialize(0, 0, 0, 0);
        return result;
    }

    update(update_context: UpdateContext) {
        this.active_state?.update(this, update_context);

        if (update_context.interval === UpdateContext.TenSecondMS) {
            //console.info(`av script:update ${update_context.interval}`);

            this.playbackQueue?.cleanupItems();
        }
    }

    startSubState(
        state: SceneGraphAudioVisualState,
        previous: SceneGraphAudioVisualState | undefined,
    ) {
        if (!this.playback) return;
        this.playback.clearQueue();
        state.loadPlayback(this.playback, previous);
        let gettingReadyCallbackCollector = new GettingReadyCallbackCollector();

        state.start();
        this.playback.beginPlayingStartOfQueue(gettingReadyCallbackCollector);
    }
}
