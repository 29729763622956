import { Action } from "../actions/action";
import { SceneObjectInterface, SceneObjectInterfaceExtensions } from "../SceneObjectInterface";

export class NavigateHomeAction extends Action {
    constructor(verb: string, name?: string, json?: any) {
        super(verb, name);
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:NavigateHome");
        const scene = SceneObjectInterfaceExtensions.sceneAsScene(interactive);

        scene?.navigateHome();
    }
}
