import { FileResourceRequest } from "../../../resources/FileResourceRequest.js";

import { FileResource } from "../../../resources/FileResource.js";
import { VisualElement } from "../visualElement.js";
import { MediaSourcePlayerWrapper } from "../resourceCanvasElements/MediaSourceVideoElementController.js";
import { VideoData } from "../resourceCanvasElements/VideoData.js";
import { MediaSourcePlayer, MIME_TYPE_MP4 } from "../resourceCanvasElements/MediaSourcePlayer.js";
import { ResourceInterface } from "./resourceInterface";
import { WebApplication } from "../../../webApplication";
import { DownloadDiagnostics } from "../../../DownloadDiagnostics";
import { ResourceCanvasElementInterface } from "../resourceCanvasElements/ResourceCanvasElement";
let c2 = require("c2.js");
const TAG = "MediaSourceResourceResolver:";

export class MediaSourceResourceResolver extends FileResource {
    static requestMp4VideoResource(request: FileResourceRequest) {
        return new MediaSourceResourceResolver(request);
    }
    static disposeMp4VideoResource(resource: ResourceInterface) {}

    static canCreateResourceFromJson(json: any, property: string) {
        return property === "video" && json[property] !== undefined;
    }
    static canCreateResourceFromJsonObject(json: any) {
        return json["video"] !== undefined;
    }
    static requestResource(name: string, path: string, path2: string, webapp: WebApplication) {
        let init = name;
        if (name.endsWith("-4k")) {
            init = name.substring(0, name.length - 2) + "init";
        } else {
            init = init + "-init";
        }

        let request = new FileResourceRequest(
            path,
            init,
            "",
            "",
            path2,
            true,
            false,
            false,
            webapp,
        );

        request.setExtension(".mp4");
        request.setCategoryPath("visuals/video/");

        //console.log(request.toURLName())
        let file_entries =
            webapp.server.server_file_cache.get_additional_manifest_entries_for_file_request(
                request,
            );
        if (file_entries?.length > 0) {
            let names = file_entries.map((each) =>
                FileResource.getFullpathFilenameWithoutExtension(each),
            );
            let selection = webapp.platform_canvas.select_video_content(names);
            if (selection) {
                request.change_to_filename(selection);
            }
        }

        // console.log(TAG, "request ", request)
        let resource = this.requestMp4VideoResource(request);

        if (resource) {
            resource.url = resource.resource_request.toUrlPath();
            resource.server_file_cache = webapp.server.server_file_cache;
            resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
            resource.type = "video";
        }

        return resource;
    }
    static createResourceFromJson(
        json: any,
        property: string,
        path: string,
        path2: string,
        webapp: WebApplication,
    ) {
        if (this.canCreateResourceFromJson(json, property)) {
            let result = this.requestResource(json.video, path, path2, webapp);
            result.videoMetadata = VideoData.newFromJson(json);
            return result;
        }
    }

    started_element_error_callback: any; // TODO: this isn't used anywhere, can it be removed?
    resource_element: HTMLVideoElement;
    resource_request: FileResourceRequest;
    isError: boolean;
    isLoaded = false;
    videoMetadata?: VideoData;
    rawData: ArrayBuffer;
    download_diagnostics: DownloadDiagnostics;

    unapply_property_values_to_resource_element = () => {};
    unapply_event_values_to_resource_element = () => {};
    cancelLoading = () => {};
    apply_property_values_to_resource_element = () => {};
    constructor(resource_request: FileResourceRequest) {
        super();
        this.resource_request = resource_request;
    }

    toSourceURL() {
        return this.resource_request.toUrlPath();
    }

    toSourceURLName() {
        return this.resource_request.name;
    }

    createResourceCanvasElement(visualElement: VisualElement) {
        return new MediaSourcePlayerWrapper(visualElement, this);
    }

    start() {}
    // /** @param {HTMLVideoElement} video_element */
    // start(video_element) {
    //   console.log(TAG, " start ", video_element)

    //   this.resource_element = video_element;
    //   this.player = new MediaSourcePlayer(this.resource_element)
    //   let url = this.resource_request.toUrlPath();

    //   this.fetchResource(url)
    //     .then(response => {
    //       this.rawData = response
    //       this.isLoaded = true
    //     })
    //     .catch(err => {
    //       this.notifyError()
    //     })

    // }

    start_loading() {}

    notifyError() {
        this.isError = true;
    }
    stop() {}

    isLoading() {
        return false;
    }

    checkForErrors() {
        return false;
    }

    createCanvasElement(resource_canvas_element: ResourceCanvasElementInterface) {
        return undefined;
    }

    pixel_size() {
        if (this.url_file_info) {
            let video_width = this.url_file_info.width;
            let video_height = this.url_file_info.height;
            if (video_width !== undefined && video_height !== undefined) {
                return [video_width, video_height];
            }
        }
        return super.pixel_size();
    }

    async fetchResource(url: string) {
        let foundFromCache = this.server_file_cache.tryGetBinaryFromCache(url);
        if (foundFromCache) {
            this.isLoaded = true;
            return Promise.resolve(foundFromCache);
        }
        let self = this;

        const request = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "arraybuffer";
            xhr.send();
            xhr.onload = function (e) {
                if (xhr.status !== 200) {
                    // onLoad();
                    reject(new Error(`Failed to download file: ${xhr.status}`));
                }

                let mimetype = MediaSourcePlayer.getMimeType(xhr.response);
                if (mimetype !== MIME_TYPE_MP4) {
                    reject(new Error(`arraybuffer is not ${MIME_TYPE_MP4} Mime type`));
                }
                self.isLoaded = true;
                self.server_file_cache.addBinaryToCache(url, xhr.response);
                resolve(xhr.response);
            };
            xhr.onerror = (e) => {
                // vid.src = null;
                reject(new Error(`Failed to download file: ${xhr.status}`));
            };
        });
        return await request;
    }

    newVideoDataForVideo(video: ArrayBuffer) {
        let result = this.videoMetadata?.clone() || new VideoData();
        result.video = video;
        result.name = this.toSourceURLName();
        return result;
    }
}
