import { Treeview } from "../view/treeview";
import { OpenModule } from "../externalModules/OpenModule";
import { ModuleIntegration } from "../externalModules/ModuleIntegration";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";

import { BaseModules, ModulesCommon } from "./ModulesCommon";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas";
import { AuthorInterface } from "../sceneAuthorInterface/authorInterface";
import { Resources } from "../resources";
import { CustomerAccount } from "../customerAccount";
import { WebApplicationServer } from "../webApplicationServer";
import { Audio } from "../audio_visual/audio/webAudio";
import { WebApplicationState } from "../WebApplicationState";
import { WebApplication } from "../webApplication";
import { ExternalModule } from "../externalModules/ExternalModule";
import page from "page";

export let dialogOptions: any = {};

export let id: string | undefined;

export class ExternalModules extends BaseModules {
    static fullscreenIframeDivId = ModulesCommon.fullscreenIframeDivId;
    static modules: ExternalModule[] = [];

    static newDialogiFrame(uuid: string, name = "module") {
        let fullscreen_iframe_div = document.getElementById(ExternalModules.fullscreenIframeDivId);

        let div_id = `module-instance-${uuid}`;
        // title="${name}"
        let div = htmlHelper.htmlToElement(`<div id=${div_id} ></div>`);

        if (fullscreen_iframe_div && fullscreen_iframe_div.parentElement && div) {
            fullscreen_iframe_div.parentElement.insertBefore(
                div,
                fullscreen_iframe_div.nextSibling,
            );
        }

        return div_id;
    }
    icanvas: InteractiveCanvas;
    authoringInterface: AuthorInterface | undefined;
    resources: Resources;
    account: CustomerAccount;
    server: WebApplicationServer;
    json: any;
    player: Audio;
    ready: boolean;
    open_modules: OpenModule[] = [];
    state: WebApplicationState;
    auth: any; // where is this used?

    constructor(
        state: WebApplicationState,
        resources: Resources,
        icanvas: InteractiveCanvas,
        account: CustomerAccount,
        server: WebApplicationServer,
        audio: Audio,
        json: any = {},
    ) {
        super();
        this.resources = resources;
        this.icanvas = icanvas;
        this.icanvas.externalModules = this;
        this.account = account;
        this.server = server;
        this.json = json;
        this.player = audio;
        this.ready = false;
        this.state = state;
        this.auth = null;
    }

    get application() {
        return this.account.application;
    }

    stroageItemName() {
        return this.resources.combineJsonResourceNameFromArray([
            this.application.name,
            this.account.name,
            "externalModules",
        ]);
    }

    initialize() {
        if (!this.json.modules) {
            this.json.modules = ExternalModules.modules;
        }
        window.addEventListener(
            "message",
            (event) => {
                this.postMessageHandler(event);
            },
            false,
        );
    }

    postMessageHandler(event: MessageEvent<any>) {
        this.open_modules.forEach((element) => {
            element.windowMessage(event);
        });
    }

    saveState() {
        // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    shutdown() {
        this.saveState();
    }

    getAuthorInterfaceName() {
        return "External Modules";
    }

    createAuthorInterfaceElement() {
        let layout = new Treeview();
        layout.buildElementFromJson(this.json, 1);
        return layout.element;
    }

    findModule(name: string) {
        for (let index = 0; index < this.json.modules.length; index++) {
            const element = this.json.modules[index];
            if (element.name === name) {
                return element;
            }
        }
    }

    findOpenModule(name: string) {
        for (let index = 0; index < this.open_modules.length; index++) {
            const element = this.open_modules[index];
            if (element.name === name) {
                return element;
            }
        }
    }

    /**
     * Opens an ExternalModule in a new tab.
     *
     * @param name the name of the module to open
     * @param isFullscreen flag to determine if the module should be opened in fullscreen TODO - deprecated
     * @param isRedirect flag to determine if the module should be opened in a new tab
     * @returns
     */
    async openModule(name: string, isFullscreen = true, isRedirect = false) {
        page.redirect("/loading");

        const isAuthed = await this.state.auth.isAuthenticated();
        const requestedPage = {
            name,
            isFullscreen,
            isRedirect,
        };
        localStorage.setItem("requestedPage", JSON.stringify(requestedPage));

        if (!isAuthed) {
            // This allows the user to abort the login process while in the loading screen.
            if (window.location.pathname !== "/loading") return;

            // Otherwise, we know the user is in the loading screen and we can redirect them to the login page.
            this.state.router.redirectToLogin();
            return;
        } else {
            this.state.router.resetPageToCanvas("login-modal");
            page.redirect("/");
        }

        const module = this.findModule(name);
        if (this.open_modules[0]) {
            this.closeModule(this.open_modules[0]);
        }
        this.ready = false;
        if (!module) return undefined;
        let uuid = uuidv4();

        // if isRedirect is set to true, isFullscreen has no effect
        if (isRedirect) {
            const redirectUrl =
                module.html.charAt(module.html.length - 1) === "/"
                    ? module.html.slice(0, module.html.length - 1)
                    : module.html;
            const returnUrl = window.location.origin;
            window.location.replace(`${redirectUrl}/?returnUrl=${returnUrl}`);
            return;
        }

        if (isFullscreen) {
            id = ExternalModules.fullscreenIframeDivId;
            this.icanvas?.deactivate();
            this.authoringInterface?.deactivate();
            this.player?.deactivate();
        } else {
            id = ExternalModules.newDialogiFrame(uuid, name);
        }

        const iframeDiv = document.getElementById(id) as HTMLElement;

        iframeDiv.style.display = "none";
        iframeDiv.style.zIndex = "100";
        iframeDiv.innerHTML = "";

        const iframe = /** @type {HTMLIFrameElement} */ document.createElement("iframe");

        let userId = ""; // should this be something?

        const openModule = new OpenModule(module, id, iframe, uuid, userId, this.state);

        if (isFullscreen) {
            iframeDiv.style.display = "block";
        }

        iframeDiv.innerHTML = "";
        openModule.externalExtensions = this;
        iframe.allow = "clipboard-write *"; // allow iframes to access clipboard

        if (isFullscreen) {
            iframe.style.height = "100vh";
            iframe.style.width = "100vw";
        } else {
            iframe.style.height = "100%";
            iframe.style.width = "100%";
        }

        iframe.onload = () => {
            iframe.contentWindow?.focus();
            openModule.start();
        };

        openModule.iframe.src = module.html;
        iframeDiv.appendChild(iframe);
        this.open_modules[0] = openModule;
        return openModule;
    }

    closeModules() {
        let i = this.open_modules.length;
        while (i--) {
            this.closeModule(this.open_modules[i]);
        }
    }

    closeModuleByName(name: string) {
        let found = this.findOpenModule(name);
        if (found) {
            this.closeModule(found);
        }
    }

    closeModuleByDivId(name: string) {
        let found = this.findOpenModule(name);
        if (found) {
            this.closeModule(found);
        }
    }

    findOpenModuleByDivId(id: string) {
        for (let index = 0; index < this.open_modules.length; index++) {
            const element = this.open_modules[index];
            if (element.iframeDivId === id) {
                return element;
            }
        }
    }

    closeModule(module: OpenModule) {
        if (module.isClosed) {
            return;
        }
        module.isClosed = true;
        this.state.router.resetPageToCanvas("login-modal");

        const iframeDiv = document.getElementById(module.iframeDivId) as HTMLElement;
        iframeDiv.style.display = "none";
        iframeDiv.innerHTML = "";

        if (module.iframeDivId === ExternalModules.fullscreenIframeDivId) {
            this.icanvas?.reactivate();
            this.authoringInterface?.reactivate();
            this.player?.reactivate();
            this.icanvas?.try_invalidated_draw();
        }
        this.open_modules = this.open_modules.filter((each) => !(each === module));
    }

    addAuthorInterfaceElementToTreeview(treeview: Treeview): void {}
}
