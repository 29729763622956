/* context

https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Migrating_from_webkitAudioContext
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Web_audio_spatialization_basics
https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices

https://developer.chrome.com/blog/autoplay/#webaudio

*/

import { CustomerAccount } from "../../customerAccount";
import { InteractiveEvent } from "../../sceneGraph/InteractiveEvent";
import { Treeview } from "../../view/treeview.js";
import { InteractiveCanvas } from "../interactive_canvas";
import { NullWebAudio } from "./NullWebAudio.js";
import { PlaylistManager } from "./playlistAPI/playlistManager.ts";
import { Audio } from "./webAudio.js";
import { actionFunction, WebAudioInterface } from "./WebAudioComponent";

export class NullWebAudioComponent implements WebAudioInterface {
    account: CustomerAccount;
    isWaitingForGesture: boolean;
    onStartAudioOnGesture: actionFunction;
    player: Audio;
    audio: any;
    playlistManager: PlaylistManager;
    constructor() {
        this.player = new NullWebAudio();
    }

    initialize() {
        this.player.tryStartAudio();
    }

    saveState() {
        //this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
    }

    start() {}

    drawFrame(icanvas: InteractiveCanvas) {}
    // /**
    //  *
    //  * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
    //  * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
    //  */
    mousedown(icanvas: InteractiveCanvas, e: InteractiveEvent) {
        this.player.startAudioOnGesture();
    }
    // /**
    //  *
    //  * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
    //  * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
    //  */
    mouseup(icanvas: InteractiveCanvas, e: InteractiveEvent) {}
    // /**
    //  *
    //  * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
    //  * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e
    //  */
    mousemove(icanvas: InteractiveCanvas, e: InteractiveEvent) {}
    // /**
    //  *
    //  * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas
    //  * @param {import('../../sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent
    //  */
    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.player.startAudioOnGesture();
    }

    onTouchTap(e: any) {
        this.player.startAudioOnGesture();
    }

    onTouchPan(e: any) {}

    onTouchSwipe(e: any) {
        this.player.startAudioOnGesture();
    }

    onTouchDistance(e: any) {}

    onTouchRotate(e: any) {}

    onTouchGesture(e: any) {}

    onCanvasResized(): void {}

    update(): void {}

    onActivity(): void {}

    activate(event: InteractiveEvent): void {}

    addAuthorInterfaceElementToTreeview(treeview: Treeview): void {}

    createAuthorInterfaceElement() {
        return undefined;
    }

    file_dropped(e: DragEvent, files?: any[]): void {}

    drag_file(e: DragEvent, files?: any[]): void {}

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent): void {}

    getAuthorInterfaceName() {
        return "";
    }
}
