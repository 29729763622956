export class GettingReadyCallback {
    name: string;
    promises: Promise<any>[] = [];
    isCanceled = false;
    isReady = false;
    error: any = null;

    constructor(name = "") {
        this.name = name;
    }

    addPromise(promise: Promise<any>) {
        this.promises.push(promise);
    }

    cancel() {
        this.isCanceled = true;
    }
}

export class GettingReadyCallbackCollector {
    calllbacks: GettingReadyCallback[] = [];
    errors: any[];
    is_auto_invalidate = false;
    isCanceled: boolean;

    cancel() {
        this.isCanceled = true;
    }

    add(callback: GettingReadyCallback) {
        this.calllbacks.push(callback);
    }

    async newWaitPromiseForAllCallbacks() {
        const allPromises = this.calllbacks.flatMap((callback) => callback.promises);

        return Promise.allSettled(allPromises).then((results) => {
            // note: indivividual callback property setting disabled, does not appear to work
            //this.calllbacks.forEach((callback) => {
            //   const callbackResults = results.filter(
            //     (result) =>{
            //       return callback.promises.includes(result.value);
            //     }
            //   );

            //   // Set error field for individual callbacks
            //   const callbackErrors = callbackResults.filter(
            //     (result) => result.status === "rejected"
            //   );
            //   callback.error = callbackErrors.length > 0 ? callbackErrors[0].reason : null;

            //   callback.isReady =
            //     callbackResults.length > 0 &&
            //     callbackResults.every((result) => result.status === "fulfilled");
            // });

            this.errors = results
                .filter((result) => result.status === "rejected")
                .map((result) => result.reason);
        });
    }
}
