import { Stem } from "../stem";

type configType = "radio" | "other";

export interface PlaylistConfig {
    name: string;
    stems: Stem[];
    gain: number;
    isLooping: boolean;
    on: boolean;
    disabled: boolean;
    type: configType;
    getParentResourcePath(): string;
}
// TODO: Rethink class
export class AudioPlaylistConfig implements PlaylistConfig {
    name: string;
    stems: Stem[];
    gain: number = 1.0;
    disabled: boolean = false;
    isLooping: boolean = true;
    on: boolean = true;
    type: configType;

    constructor(config: any) {
        // TODO Validate Add error handling
        this.name = config.name;
        this.stems = config.stems;
        this.gain = config.gain;
        this.type = config.type;
        if (!this.gain) {
            this.gain = 0.5;
        }
    }

    getParentResourcePath() {
        return "music"; // @TODO: Fix MAGIC STRING
    }
}
