import { InteractiveCanvas } from "../audio_visual/interactive_canvas";

export class InteractiveEvent {
    icanvas?: InteractiveCanvas;
    e?: Event;
    isStopPropagation: boolean = false;
    activate_value: any;
    activate_value_context: any;

    constructor(icanvas?: InteractiveCanvas, e?: Event) {
        if (icanvas) {
            this.icanvas = icanvas;
        }
        if (e) {
            this.e = e;
        }
    }

    private isKeyboardEvent(event: Event): event is KeyboardEvent {
        return event instanceof KeyboardEvent;
    }
    private isMouseEvent(event: Event): event is MouseEvent {
        return event instanceof MouseEvent;
    }
    private isTouchEvent(event: Event): event is TouchEvent {
        return event instanceof TouchEvent;
    }
    private isDragEvent(event: Event): event is DragEvent {
        return event instanceof DragEvent;
    }
    get e_keyboardevent(): any {
        return this.e;
        //return this.e && this.isKeyboardEvent(this.e) ? this.e : undefined;
    }
    get e_mouseevent(): MouseEvent | undefined {
        return this.e && this.isMouseEvent(this.e) ? this.e : undefined;
    }
    get e_touchevent(): TouchEvent | undefined {
        return this.e && this.isTouchEvent(this.e) ? this.e : undefined;
    }
    get e_dragevent(): DragEvent | undefined {
        return this.e && this.isDragEvent(this.e) ? this.e : undefined;
    }
    preventDefaultAndStopPropagation(): void {
        this.isStopPropagation = true;
        this.e?.preventDefault?.();
        this.e?.stopPropagation?.();
    }
}
