import { InternalAuth } from "../../../../auth/InternalAuth";

export function successPage() {
    const authorizationCode = new URLSearchParams(window.location.search).get("code");
    const connectionID = new URLSearchParams(window.location.search).get("state");
    const viewport = document.getElementById("viewport");
    const loginContainer = document.createElement("div");

    // Notify the backend of the authorization code so the TV can receive the authenticated status.
    notifyBackend(connectionID, authorizationCode);

    viewport!.innerHTML = "";
    document.getElementById("debug-table")?.remove();
    viewport!.appendChild(loginContainer);
    loginContainer.innerHTML = `
        <div class="login-page" id="success">
            <h2>Success!</h2>
            <p>You may leave this page.</p>
        </div>
    `;
}

/**
 * Notifies the backend of the device code and authorization code.
 *
 * @param connectionID the connection ID (deviceID) of the WS connection
 * @param authorizationCode the authorization code received from the OAuth server
 */
function notifyBackend(connectionID: string | null, authorizationCode: string | null) {
    try {
        if (!connectionID || !authorizationCode) {
            console.log("Error - connectionID or authorizationCode is null");
            return;
        }
        console.log("attempting to send login session to backend");
        fetch(InternalAuth.AUTH_API_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                action: "notify_code",
                connection_id: connectionID,
                authorization_code: authorizationCode,
            }),
        }).then((response) => {
            if (response.ok) {
                console.log("info sent to backend");
            } else {
                console.log("Error - info not sent to backend: ", response);
            }
        });
    } catch (err) {
        console.log("Error while sending device code to backend: ", err);
    }
}
