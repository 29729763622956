// @ts-check

import { MediaSourcePlayer } from "./resourceCanvasElements/MediaSourcePlayer.js";
import * as htmlHelper from "../../htmlHelper.js";
import { RectangleGeometry } from "../../geometry/RectangleGeometry.js";
import { WebApplicationState } from "../../WebApplicationState";
import { InteractiveCanvas, InteractiveCanvasComponentInterface } from "../interactive_canvas";
import { InteractiveMobileCanvas } from "../interactive_mobile_canvas";
import { InteractiveMouseEvent } from "../../MouseEvent";
import { InteractiveEvent } from "../../sceneGraph/InteractiveEvent";
import { UpdateContext } from "../../update";
import { WebApplicationSettings } from "../../WebApplicationSettings";

export type actionFunction = () => any; // TODO: there are multiple actionFunctions that are all the same. do we need them?

/**
 *
 */
export class MeidaSourcePlayerComponent implements InteractiveCanvasComponentInterface {
    static isSupportedByPlatform() {
        return "MediaSource" in window;
    }

    player: MediaSourcePlayer;
    webApplicationState: WebApplicationState;
    buffer_video_element?: HTMLVideoElement;
    buffer_video_index: number;

    // /**
    //  * @type {actionFunction}
    //  */
    //onStartOnGesture;

    constructor(webApplicationState: WebApplicationState) {
        this.webApplicationState = webApplicationState;
    }

    onCanvasResized() {
        let image_rect = this.webApplicationState.simulation.geometry.get_absolute_rect_shape();

        this.player.setVideoSize(image_rect.w, image_rect.h);
    }
    /**
     *
     */
    async initialize() {
        this.buffer_video_index = 0;

        this.buffer_video_element = document.getElementById("video-") as HTMLVideoElement;

        if (MeidaSourcePlayerComponent.isSupportedByPlatform()) {
            htmlHelper.showElement(this.buffer_video_element);

            let highFrequencyConsoleLogger = this.webApplicationState.author.newDirectLogger(1);

            this.player = new MediaSourcePlayer(
                this.buffer_video_element,
                highFrequencyConsoleLogger,
            );

            // if(this.webApplicationState.application.getSetting(WebApplicationSettings.isExperiment01Enabled_SettingName))
            // {
            //     this.player.isWaitUntilReadyOnPlay=false;
            // }

            const playerReady = new Promise((resolve) => {
                this.player.addEventListener("ready", async () => {
                    resolve("PLAYER READY");
                });
            });

            this.player.initialize();
            await playerReady;
        } else {
            // Fallback: Load video directly. Added by Braden as a temporary workaround.
            console.warn("MediaSource API not supported. Using fallback video source.");
            await this.buffer_video_element.play().catch((err) => {
                console.error("Fallback playback failed:", err);
            });
        }
    }

    start() {}

    startAOnGesture() {
        this.player.resumeOnGesture();
    }

    onActivity() {}

    drawFrame(icanvas: InteractiveCanvas) {}

    mousedown(icanvas: InteractiveCanvas, e: InteractiveEvent) {
        this.startAOnGesture();
    }

    mouseup(icanvas: InteractiveCanvas, e: InteractiveEvent) {}

    mousemove(icanvas: InteractiveCanvas, e: InteractiveEvent) {}

    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        this.startAOnGesture();
    }

    onTouchTap(e: any) {
        this.startAOnGesture();
    }

    onTouchPan(e: any) {}

    onTouchSwipe(e: any) {
        this.startAOnGesture();
    }

    onTouchDistance(e: any) {}

    onTouchRotate(e: any) {}

    onTouchGesture(e: any) {}

    saveState() {}

    shutdown() {
        this.saveState();
    }

    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent): void {}

    update(update_context: UpdateContext): void {}

    file_dropped(e: DragEvent, files?: any[]): void {}

    drag_file(e: DragEvent, files?: any[]): void {}

    activate(event: InteractiveEvent): void {}
}
