import IDisposable from "../../../interfaces/IDisposable";
import { FileResourceRequest } from "../../../resources/FileResourceRequest";
import { SceneObjectInterface } from "../../../sceneGraph/SceneObjectInterface";
import { fileDownload } from "./util";

export class AudioResource implements IDisposable {
    resource_element: ArrayBuffer;
    resource_request: FileResourceRequest;
    loading_promise?: Promise<any>;
    loading_promises: (Promise<any> | undefined)[] = [];

    constructor(resource_request: FileResourceRequest) {
        this.resource_request = resource_request;

        this.loading_promise = fileDownload(this.resource_request.toUrlPath());

        this.loading_promise.then((buffer) => {
            this.resource_element = buffer;
        });

        this.loading_promises.push(this.loading_promise);
    }
    checkForErrors() {
        return false;
    }

    toSourceURLName() {
        return this.resource_request.name;
    }

    initialize(scene?: SceneObjectInterface) {}

    dispose() {
        let isLoading = this.isLoading();
        if (isLoading) {
            let self = this;
            let and_then = () => {
                self.dispose();
            };
            this.addLoadingThen(and_then);
        } else {
            console.log(`audio:resource:close AudioResource ${this.resource_request.toUrlPath()}`);
        }
    }

    addLoadingThen(and_then: any) {
        let latest = this.getLoadingPromise();

        if (!latest) {
            latest = new Promise((resolve, reject) => {
                resolve(true);
            });
        }

        let index: number;
        index = this.loading_promises.length;
        let next = latest.then(and_then);
        next = next.catch((e) => {
            console.error(e);
        });
        next = next.finally(() => {
            this.loading_promises[index] = undefined;
        });

        this.loading_promises.push(next);
    }

    isLoading() {
        return this.getLoadingPromise() !== undefined;
    }

    getLoadingPromise() {
        return this.loading_promises.length === 0
            ? undefined
            : this.loading_promises[this.loading_promises.length - 1];
    }
}
