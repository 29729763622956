import { AudioVisualPlaybackQueue } from "../../audio_visual/visual/AudioVisualPlaybackQueue";
import { AudioVisualPlaybackQueueItem } from "../../audio_visual/visual/AudioVisualPlaybackQueueItem";
import { AudioVisualScriptResourceCanvasElement } from "../../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement";

import { UpdateContext } from "../../update";
import { WebApplicationSettings } from "../../WebApplicationSettings.js";
import { AudioVisualStateEventsScheduler } from ".././audio-visual-script/AudioVisualStateEventsScheduler.js";
import { SceneGraphAudioVisualScript } from "./SceneGraphAudioVisualScript";
import { SceneGraphAudioVisualScriptItem } from "./SceneGraphAudioVisualScriptItem";
import { SceneGraphAudioVisualStateBaseComponent } from "./SceneGraphAudioVisualStateBaseComponent";
import { SceneGraphAudioVisualStateEventComponent } from "./SceneGraphAudioVisualStateEventComponent";
import { SceneGraphAudioVisualStateSet } from "./SceneGraphAudioVisualStateSet";

export class SceneGraphAudioVisualState {
    json: any;
    base_component: SceneGraphAudioVisualStateBaseComponent;
    event_components: any;
    script: SceneGraphAudioVisualScript | undefined;
    av_states: SceneGraphAudioVisualStateSet;
    name: string;
    transitionFromMap: Map<string, SceneGraphAudioVisualState>;
    scheduler: AudioVisualStateEventsScheduler;

    constructor(json: any, av_states: SceneGraphAudioVisualStateSet, name: string) {
        this.json = json;
        this.av_states = av_states;
        this.name = name;
    }

    initializeFromJson() {
        if (this.json.script) {
            this.script = this.av_states.getScripts().getScriptByName(this.json.script);
            return;
        }

        if (this.json.components) {
            this.base_component = new SceneGraphAudioVisualStateBaseComponent(
                this.json.components.base,
                "base",
            );
            this.base_component.initializeFromJson();

            if (this.json.components.events) {
                this.event_components = {};
                for (let each in this.json.components.events) {
                    this.event_components[each] = new SceneGraphAudioVisualStateEventComponent(
                        this.json.components.events[each],
                        each,
                    );
                    this.event_components[each].initializeFromJson();
                }
            }
        }
        if (this.transition_from) {
            this.transitionFromMap = new Map();
            for (const each in this.transition_from) {
                let each_json = this.transition_from[each];
                let state = new SceneGraphAudioVisualState(each_json, this.av_states, each);
                this.transitionFromMap.set(each, state);
            }
        }
    }

    start() {
        if (this.script) {
            this.script.start();
            return;
        }
    }
    loadPlayback(
        playbackQueue: AudioVisualPlaybackQueue,
        previousState?: SceneGraphAudioVisualState,
    ) {
        if (
            playbackQueue.application.settings.getSetting(
                WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
            )
        ) {
            console.log(`SceneGraphAudioVisualState::loadPlayback ${this.name}`);
        }

        if (this.script) {
            this.script.loadPlayback(playbackQueue, previousState);
            return;
        }

        let update_context = new UpdateContext();
        update_context.start_time = Date.now();
        update_context.time = update_context.start_time;
        update_context.deltaTime = 0;

        if (previousState) {
            let transition = this.transitionFromMap?.get(previousState.name);
            if (transition) {
                let playbackItem = transition.createPlaybackItem();
                playbackItem.is_looping = false;
                playbackQueue.pushItem(playbackItem);
            }
        }

        if (this.hasComponents()) {
            //this.initalizePlaybackQueueFromComponentsTest(playbackQueue);
            if (!this.hasEvents()) {
                let item = this.createPlaybackQueueItemFromJsonObject(
                    this.base_component.long,
                    true,
                    false,
                    this.base_component.name,
                    SceneGraphAudioVisualStateBaseComponent.QueueItemType,
                );
                playbackQueue.pushItem(item);
            } else {
                this.scheduler = new AudioVisualStateEventsScheduler(this);
                this.scheduler.refresh(playbackQueue, update_context);
            }
        } else {
            playbackQueue.pushItem(this.createPlaybackItem());
        }
    }

    /**
     *
     * @returns {boolean}
     */
    hasComponents() {
        return this.base_component !== undefined;
    }

    /**
     *
     * @returns {boolean}
     */
    hasEvents() {
        return this.event_components !== undefined;
    }

    /**
     *
     * @param {import('../audio_visual/visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement.js').AudioVisualScriptResourceCanvasElement} av_script
     * @param {UpdateContext} update_context
     */
    update(av_script: AudioVisualScriptResourceCanvasElement, update_context: UpdateContext) {
        if (this.script) {
            this.script.update(av_script, update_context);
            return;
        }

        if (
            this.scheduler &&
            update_context.interval === UpdateContext.FiveSecondMS &&
            this.hasComponents() &&
            this.hasEvents()
        ) {
            if (
                av_script.playbackQueue?.application.settings.getSetting(
                    WebApplicationSettings.isAudioVideoScriptLoggingEnabled_SettingName,
                )
            ) {
                //  console.log(`SceneGraphAudioVisualState:${this.name}::update ${update_context.time}`)
            }

            if (av_script.playback) this.scheduler.refresh(av_script.playback, update_context);
            else console.error("Playback queue is undefined");
        }
    }

    // initalizePlaybackQueueFromComponentsTest(playbackQueue: AudioVisualPlaybackQueue) {

    //     let item = this.createPlaybackQueueItemFromJsonObject(this.base_component.medium, false, true);
    //     playbackQueue.pushItem(item);

    //     // for (let index = 0; index < 3; index++) {

    //     //   item = this.createPlaybackQueueItemFromJsonObject(this.base_component.medium, false, false);
    //     //    playbackQueue.pushItem(item);
    //     // }
    //     // for (let index = 0; index < 1; index++) {

    //     item = this.createPlaybackQueueItemFromJsonObject(this.base_component.short, false, false);
    //     playbackQueue.pushItem(item);
    //     // }

    //     item = this.createPlaybackQueueItemFromJsonObject(this.base_component.long, true, false);
    //     playbackQueue.pushItem(item);
    // }

    createPlaybackQueueItemFromJsonObject(
        json: any,
        isLooping = true,
        is_play_immediate = true,
        name?: string,
        type?: string,
    ) {
        let stems = [];
        let video_stem = {
            video: json["video"],
        };
        stems.push(video_stem);

        let item = new AudioVisualPlaybackQueueItem(stems);
        item.name = name;
        item.type = type;
        item.is_looping = isLooping;
        item.is_play_immediate = is_play_immediate;
        return item;
    }

    createPlaybackItem() {
        let stems = [];

        if (this.json["video.suffix"]) {
            let video_stem = {
                video:
                    this.av_states.av_script_resource.video_base_name + this.json["video.suffix"],
            };

            // -4k upgrade

            stems.push(video_stem);
        } else if (this.json["video"]) {
            let video_stem = {
                video: this.json["video"],
            };

            // -4k upgrade

            stems.push(video_stem);
        }

        if (this.json["ambiance.suffix"]) {
            let audio_stem = {
                gain: this.json["ambiance.gain"],
                audioSrc:
                    this.av_states.av_script_resource.audio_base_name +
                    this.json["ambiance.suffix"],
            };
            stems.push(audio_stem);
        }

        if (this.json["audioAmbiance"]) {
            let audioAmbiance_stem = {
                audioAmbiance: this.json["audioAmbiance"],
            };
            stems.push(audioAmbiance_stem);
        }

        let item = new AudioVisualPlaybackQueueItem(stems);
        return item;
    }

    get isDefault() {
        return this.json.isDefault;
    }

    get video() {
        return this.json["video"];
    }

    get video_suffix() {
        return this.json["video.suffix"];
    }

    get image_suffix() {
        return this.json["image.suffix"];
    }

    get ambiance_suffix() {
        return this.json["ambiance.suffix"];
    }

    get ambiance_gain() {
        return this.json["ambiance.gain"];
    }

    get audioAmbiance() {
        return this.json["audioAmbiance"];
    }

    get transition_from() {
        return this.json["transition.from"];
    }

    get events(): SceneGraphAudioVisualStateEventComponent[] {
        return Object.values(this.event_components);
    }

    eventsSortedByMostRare() {
        return this.events.sort(
            (a, b) => b.occurrenceIntervalMilliseconds - a.occurrenceIntervalMilliseconds,
        );
    }
}
