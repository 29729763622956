// @ts-check

import { ConnectedStem } from "./ConnectedStem";
import IConnectedStems from "./interfaces/IConnectedStems";
import { Stem } from "./stem";
import { Audio, WebAudio } from "./webAudio";

export class ConnectedStems implements IConnectedStems {
    collection: ConnectedStem[] = [];
    effects: ConnectedStem[] = [];
    //collection_unreferenced = [];
    audio: Audio;

    constructor(audio: Audio) {
        this.audio = audio;
    }

    startAudioOnGesture() {
        let now = Math.floor(Date.now() / 1000);
        this.collection.forEach((element) => {
            element.startAudioOnGesture(now);
        });
    }

    addReference(stem: Stem) {
        let found = this.findOrAddConnectionForStem(stem);
        found?.addReference(stem);
    }

    removeReference(stem: Stem) {
        let found = this.findConnectionForStem(stem);
        found?.removeReference(stem);
    }

    findConnectionForStem(stem: Stem) {
        for (let index = 0; index < this.collection.length; index++) {
            const connectedStem = this.collection[index];
            if (connectedStem.audioSrc === stem.audioSrc) {
                return connectedStem;
            }
        }

        return undefined;
    }

    findConnectionForEffectStem(stem: Stem) {
        for (let index = 0; index < this.effects.length; index++) {
            const connectedStem = this.effects[index];
            if (connectedStem.audioSrc === stem.audioSrc) {
                return connectedStem;
            }
        }

        return undefined;
    }

    findConnectionForAnyStem(stem: Stem) {
        let found = this.findConnectionForStem(stem);
        if (found) {
            return found;
        }
        return this.findConnectionForEffectStem(stem);
    }

    findOrAddConnectionForStem(stem: Stem) {
        let found = this.findConnectionForStem(stem);
        if (found) {
            return found;
        }

        let toAdd = new ConnectedStem(this.audio, stem.audioSrc);
        this.collection.push(toAdd);
        return toAdd;
    }

    loadStem(stem: Stem) {
        let found = this.findOrAddConnectionForStem(stem);
        stem.load(found);
    }

    unloadStem(stem: Stem) {
        let found = this.findOrAddConnectionForStem(stem);
        stem.unload(found);
    }

    playStem(stem: Stem) {
        let found = this.findOrAddConnectionForStem(stem);
        stem.play(found);
    }

    updateStem(stem: Stem) {
        let found = this.findConnectionForStem(stem);
        if (found) {
            if ((found.gain = stem.json.gain)) {
                found.setGain(stem.json.gain, true);
            }
        }
    }

    setGainStem(stem: Stem, amount: number) {
        let found = this.findOrAddConnectionForStem(stem);

        stem.setGain(found, amount);
    }

    stopStem(stem: Stem) {
        let found = this.findConnectionForStem(stem);
        if (found) {
            stem.stop(found);
            return;
        }

        found = this.findConnectionForEffectStem(stem);
        if (found) {
            stem.stop(found);
            found.onDone();
        }
    }

    addConnectionForEffectStem(stem: Stem) {
        if (!stem.isEnabled) {
            return;
        }

        let toAdd = new ConnectedStem(this.audio, stem.audioSrc);
        this.effects.push(toAdd);
        return toAdd;
    }

    playSoundEffect(stem: Stem) {
        let found = this.addConnectionForEffectStem(stem);
        if (!found) {
            return;
        }

        let effect = /** @type {ConnectedStem} */ found;
        effect.addReference(stem);
        effect.playFrom(stem);

        let self = this;
        effect.onDone = () => {
            const index = self.effects.indexOf(effect);
            if (index > -1) {
                self.effects.splice(index, 1);
            }
        };
    }
}
