import { response } from "express";
import { showLoadingPage } from "../routing/routes/loading/loading";
import { WebApplication } from "../webApplication";
import { WebApplicationState } from "../WebApplicationState";
import QRCode from "qrcode";

export interface AuthInterface {
    initialize(): void;
    saveState(): void;
    shutdown(): void;
    isAuthenticated(callback: (isAuthed: boolean) => void): void;
    createLoginSession(redirectURL: string | undefined): void;
    createGuestSession(): void;
    showQRCode(connectionID: string, challenge: string): void;
}

export class InternalAuth implements AuthInterface {
    static AUTH_API_URL = "https://lakeside-auth.nmp.nonprod-sinclairstoryline.com/auth/nonprod";
    static AUTH_WS_URL = "wss://6hjs3llxt3.execute-api.us-west-2.amazonaws.com/nonprod";
    state: WebApplicationState;
    authenticated: boolean;
    isGuest: boolean;
    ws: WebSocket | null;
    connectionID: string | null;
    verifier: string | null;
    isQA: boolean;

    constructor(state: WebApplicationState) {
        this.state = state;
        // Default to using QA endpoints when on any test site or when the setting is enabled.
        this.isQA =
            this.state.application.getSetting(WebApplication.IsAuthUsingQAEndpointSettingName) ||
            window.location.host.includes("test");
        this.isGuest = false;
        this.ws = null;
        this.connectionID = null;
        this.verifier = null;
    }

    /**
     * Initialize the auth system
     */
    initialize() {
        if (this.isQA) {
            InternalAuth.AUTH_API_URL =
                "https://qa-lakeside-auth.nmp.nonprod-sinclairstoryline.com/auth/qa";
            InternalAuth.AUTH_WS_URL = "wss://26zaxapqea.execute-api.us-west-2.amazonaws.com/qa";
        }
    }

    /**
     * Creates a guest session for the user.
     */
    createGuestSession() {
        showLoadingPage(this.state);
        console.log("create guest session");
        fetch(InternalAuth.AUTH_API_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ action: "guest" }),
            credentials: "include",
        }).then((response) => {
            if (response.ok) {
                // Allow the user to continue to the requested module as a guest.
                console.log("guest session created");
                this.isGuest = true;
                const requestedPageString = localStorage.getItem("requestedPage");
                const requestedPage = requestedPageString
                    ? JSON.parse(requestedPageString)
                    : undefined;
                this.state.externalModules.openModule(
                    requestedPage.name,
                    requestedPage.isFullscreen,
                    requestedPage.isRedirect,
                );
            }
        });
    }

    /**
     * Checks if a user is authenticated by sending the JWT to the backend.
     * This function is called immediately upon loading the page.
     * If manually overridden will return true, bypassing authentication.
     * @param {Function} callback - Function to call with the authentication result.
     */
    async isAuthenticated() {
        console.log("checking if user is authenticated");

        // TODO - users can just change their local storage to get around auth. Security issue down the line.
        // Guessing this won't be an issue in production because there will not be a debug menu.
        if (!this.state.application.getSetting(WebApplication.IsAuthEnabledSettingName)) {
            console.log("auth is disabled, skipping authentication check");
            return true;
        }

        try {
            const response = await fetch(InternalAuth.AUTH_API_URL, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
            });

            if (!response.ok) {
                console.error("error checking authentication: ", response);
                return false;
            }

            const data = await response.json();

            if (!data.ok) {
                console.error("error checking authentication: ", data);
                return false;
            }

            return true;
        } catch (error) {
            console.error("Error during authentication check:", error);
            return false;
        }
    }

    /**
     * Creates a login session for the user. This function is called when the user clicks the login button.
     * @param {String | undefined} redirectURL the URL to redirect to after login.
     */
    createLoginSession(redirectURL: string | undefined = undefined) {
        const ws = new WebSocket(InternalAuth.AUTH_WS_URL);

        ws.onopen = () => {
            console.log("ws connection opened");

            // Create the login session to get a connectionID and create a qr code
            ws.send(JSON.stringify({ action: "login" }));

            // Ping the WS every 30s to keep the connection alive
            // TODO - play around with this number
            const interval = setInterval(() => {
                if (ws.readyState === ws.CLOSED || ws.readyState === ws.CLOSING) {
                    clearInterval(interval);
                    return;
                }
                ws.send(JSON.stringify({ action: "ping" }));
            }, 30000);
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event?.data);
            console.log("received message from ws");
            if (!data) return;
            if (data.connectionID) {
                // Update the connection ID in the URL
                this.connectionID = data.connectionID;
                console.log("received connection id, updating query params: ", data.connectionID);
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set("id", data.connectionID);
                window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
            } else if (data.status === "authenticated") {
                console.log("received authenticated status");
                // send the code_verifier and connection ID to the backend
                this.handleAuthenticatedStatus(redirectURL);
                ws.close();
            }
        };

        ws.onclose = () => {
            console.log("ws connection closed");
        };
        this.ws = ws;
    }

    /**
     * This function is called once the WS receives the authenticated status, indicating that
     * the user has successfully authenticated and their login session can be closed.
     *
     * @param redirectURL the URL to redirect to after login.
     */
    async handleAuthenticatedStatus(redirectURL: string | undefined) {
        try {
            const response = await fetch(InternalAuth.AUTH_API_URL, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                    action: "send_code_verifier",
                    connection_id: localStorage.getItem("deviceID"), // protect against CSRF, identify the device
                    code_verifier: this.verifier, // the code verifier used to generate the challenge, and solve it
                }),
            });

            if (!response.ok) {
                console.error("error sending code verifier: ", response);
                return;
            }
            const data = await response.json();
            if (!data.ok) {
                console.error("error sending code verifier: ", data);
                return;
            }
            console.log("received user info: ", data.ok);

            // Since we are going to a redirect either way, do not need to return from the function.
            if (!!redirectURL) {
                const requestedPageString = localStorage.getItem("requestedPage");
                if (!requestedPageString) {
                    console.error("requested page string was null");
                    return;
                }
                // The module is a json string in local storage.
                const requestedPage = JSON.parse(requestedPageString);
                // Open the requested module.
                this.state.externalModules.openModule(
                    requestedPage?.name,
                    requestedPage?.isFullscreen,
                    requestedPage?.isRedirect,
                );
            }
        } catch (error) {
            console.log("Error retrieving user info: ", error);
        }
    }

    /**
     * Creates a QR code for the user to scan. The URL contains the
     * code challenge and connection ID for an Auth0 Authorization Flow login.
     *
     * @param connectionID the connection ID to use for the QR code.
     * @param challenge the code challenge to use for the QR code
     */
    showQRCode(connectionID: string, challenge: string) {
        console.log("creating qr code");

        // Callback to the /loginsuccess page, which will notify the backend of the authorization code.
        const callbackURL = `${window.location.origin}/loginsuccess`;

        const qrCodeURL = `https://dev-vtk6bmabwzadnczf.us.auth0.com/authorize?response_type=code&code_challenge=${challenge}&code_challenge_method=S256&client_id=Gs7W4sbq5zyuWmeqd02a4Vucc6yASuGz&redirect_uri=${callbackURL}&scope=openid%20offline_access%20profile%20email&state=${connectionID}`;

        // Temporary solution to display the QR code URL.
        console.log(qrCodeURL);

        QRCode.toCanvas(
            document.getElementById("qr-code"),
            qrCodeURL,
            { color: { dark: "#230c01", light: "#d9b996" }, width: 400, margin: 2 },
            function (error) {
                if (error) {
                    console.error(error);
                }
            },
        );
    }

    /**
     * Encodes a string to base64url.
     *
     * @param inputStr the string to encode.
     * @returns the base64url encoded string.
     */
    base64urlencode(inputStr: ArrayBuffer | ArrayLike<number>) {
        let str = "";
        const bytes = new Uint8Array(inputStr);
        for (let i = 0; i < bytes.byteLength; i++) {
            str += String.fromCharCode(bytes[i]);
        }
        return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    }

    /**
     * Creates a SHA-256 hash of the given string.
     *
     * @param plain the string to hash.
     * @returns the SHA-256 hash of the string.
     */
    sha256(plain: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest("SHA-256", data);
    }

    /**
     * Creates a new code verifier+challenge set for the login session.
     * See the docs for more information: https://auth0.com/docs/api-auth/tutorials/authorization-code-grant-pkce
     */
    async generateCodeVerifierAndChallenge() {
        const verifier = Array.from(crypto.getRandomValues(new Uint32Array(56 / 2)), (str) => {
            return ("0" + str.toString(16)).slice(-2);
        }).join("");
        console.log("generated verifier: ", verifier);

        // Hash the verifier and then url encode it to generate the challenge
        const hashed = await this.sha256(verifier);
        const challenge = this.base64urlencode(hashed);
        console.log("generated challenge: ", challenge);
        this.verifier = verifier;
        return { verifier, challenge };
    }

    // TODO - what is this for?
    saveState() {
        console.log("Internal Auth Save State");
    }

    shutdown() {
        this.saveState();
    }
}
