import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope";
import { AudioAmbianceSet } from "../audio_visual/audio/audioAmbianceSet";
import { AmbianceAudioListener } from "../audio_visual/audio/audioListener";
import { InteractiveEvent } from "./InteractiveEvent";
import { DragDropEvent } from "./DragDropEvent";
import { VisualElements } from "../audio_visual/visual/VisualElements";
import { WebApplication } from "../webApplication";
import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
import { ScenePath } from "./ScenePath";
import { WebApplicationSettings } from "../WebApplicationSettings";
import { Scene } from "./scene";
import { SceneChangeRequest } from "./SceneChangeRequest";
import { Resources } from "../resources.js";
import { InteractiveCanvas } from "../audio_visual/interactive_canvas.js";
import { WebApplicationServer } from "../webApplicationServer.js";
import { LocalServerFileCache } from "../LocalServerFileCache.js";
import { SceneGraphSet } from "./sceneGraphSet.js";
import { UpdateContext } from "../update.js";
import { Simulation } from "../simulation";

let c2 = require("c2.js");

export class CommonScenes {
    static UXSceneName = "ux-scene";
    static UXSceneGraphName = "ux";
}

export class SceneGraphNode {
    active_scene?: Scene;
    ux_scene?: Scene;
    scenes: Scene[];
    simulation: Simulation;
    listener: AmbianceAudioListener;
    visual_elements: VisualElements;
    event_scene_change_request_complete?: (request: SceneChangeRequest) => void;
    scene_change_requests_in_progress: SceneChangeRequest[] = [];
    ux_scene_loading_promise?: Promise<any>;

    constructor(simulation: Simulation) {
        this.scenes = [];
        this.simulation = simulation;
        this.visual_elements = new VisualElements(this);
    }
    get application() {
        return this.simulation.application;
    }

    get firstScene() {
        return this.scenes[0];
    }

    get audio() {
        return this.simulation.audio;
    }

    get resources() {
        return this.simulation.resources;
    }

    get icanvas() {
        return this.simulation.icanvas;
    }

    get visualElements() {
        return this.visual_elements;
    }

    get server() {
        return this.simulation.server;
    }

    get server_file_cache() {
        return this.server.server_file_cache;
    }

    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    async initiaize() {
        let a = this.audio;
        this.listener = new AmbianceAudioListener(a);
        this.audio.onStartAudioOnGesture = () => {
            this.onStartAudioOnGesture();
        };

        if (this.application.getSetting(WebApplicationSettings.isUXSceneEnabled_SettingName)) {
            this.ux_scene_loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(
                CommonScenes.UXSceneGraphName,
            );
            this.ux_scene_loading_promise = this.ux_scene_loading_promise?.then((result) => {
                this.ux_scene_loading_promise = undefined;

                return result;
            });
        }
    }

    startUXScene() {
        let ux = this.sceneGraphSet.getSceneGraph(CommonScenes.UXSceneGraphName);

        if (ux) {
            this.ux_scene = ux.defaultOrFirstScene;

            if (this.ux_scene) {
                this.ux_scene.scene_graph_node = this;
                this.ux_scene.start(undefined);
                return;
            }
        }

        console.warn(`ux scene not found`);
    }

    onStartAudioOnGesture() {
        this.listener?.onStartAudioOnGesture();
    }

    getUXScene() {
        return this.ux_scene;
    }

    getActiveScene() {
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name === CommonScenes.UXSceneName) {
                continue;
            }
            return element;
        }
        return undefined;
    }

    startSceneChange(request: SceneChangeRequest, fallback_request_on_error?: SceneChangeRequest) {
        if (this.scene_change_requests_in_progress.length > 0) {
            console.warn("startSceneChange ignored: scene change in progress");
            return;
        }

        for (const each_request_in_progress of this.scene_change_requests_in_progress) {
            if (
                ScenePath.areLocationsTheSame(request.location, each_request_in_progress.location)
            ) {
                console.warn("startSceneChange ignored: same location in progress");
                return;
            }
        }

        this.simulation.incrementItemSet();

        request.isPrimary = true;
        this.scene_change_requests_in_progress.push(request);

        let name = request.location.sceneGraphName;
        if (this.sceneGraphSet.isSceneGraphFetched(name)) {
            this.performSceneChange(request);

            if (fallback_request_on_error && request.error) {
                this.performSceneChange(fallback_request_on_error);
            }
        } else {
            request.loading_promise = this.sceneGraphSet.fetchSceneGraphPromise(name);
            if (!request.loading_promise) {
                console.error("loading_promise is undefined");
                return;
            }
            request.loading_promise = request.loading_promise.then((result) => {
                request.loading_promise = undefined;
                if (
                    !request.isCancled &&
                    this.scene_change_requests_in_progress.some((obj) => obj === request)
                ) {
                    this.performSceneChange(request);

                    if (fallback_request_on_error && request.error) {
                        this.performSceneChange(fallback_request_on_error);
                    }
                }
                return result;
            });
        }
    }

    isPreloadConnectedSceneGraphContentEnabled() {
        return this.simulation.application.getSetting(
            WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName,
        );
    }
    performSceneChange(request: SceneChangeRequest) {
        let name = request.location.sceneGraphName;

        let found = this.sceneGraphSet.getSceneGraph(name);

        if (found === undefined) {
            request.error = `scene graph not found ${name}`;
            console.error(request.error);
            this.event_scene_change_request_complete?.(request);

            request.isCancled = true;
            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );
            return;
        }

        if (request.location.sceneName === undefined) {
            request.location.sceneName = found.defaultOrFirstScene?.name;
        }

        let found_scene = found.findSceneByName(request.location.sceneName);

        if (found_scene === undefined) {
            request.error = `scene not found ${request.location.sceneName}`;
            console.error(request.error);
            this.event_scene_change_request_complete?.(request);

            request.isCancled = true;
            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );

            return;
        }

        if (this.isPreloadConnectedSceneGraphContentEnabled() || this.simulation.state.msePlayer) {
            if (!found_scene.isVisualContentReady() && !request.callback_collector) {
                let callback_collector = new GettingReadyCallbackCollector();
                found_scene.startVisualContentGetReady(callback_collector);

                request.callback_collector = callback_collector;
                request.callback_collector_promise = request.callback_collector
                    .newWaitPromiseForAllCallbacks()
                    .then((result) => {
                        request.callback_collector_promise = undefined;
                        this.performSceneChange(request);
                    });

                return;
            }
        }
        let previous_active_scene = undefined;

        if (this.active_scene) {
            this.active_scene.stop(found_scene);
            this.active_scene.scene_graph_node = undefined;
            previous_active_scene = this.active_scene;
            this.active_scene = undefined;
        }

        this.active_scene = found_scene;

        if (this.active_scene) {
            this.active_scene.scene_graph_node = this;

            this.scene_change_requests_in_progress = this.scene_change_requests_in_progress.filter(
                (obj) => obj !== request,
            );

            this.scenes = [this.active_scene];

            if (
                !this.ux_scene &&
                this.active_scene.json.excludeSceneLayer !== CommonScenes.UXSceneName
            ) {
                this.startUXScene();
            }

            if (this.ux_scene) {
                this.scenes.push(this.ux_scene);
            }
            this.active_scene?.start(previous_active_scene);
        }

        request.isCompleted = true;
        this.event_scene_change_request_complete?.(request);
        this.invalidate();
    }

    invalidate() {
        this.icanvas.invalidate();

        this.icanvas.try_invalidated_draw();
    }

    findSceneByPath(path: ScenePath) {
        return this.findSceneByName(path.sceneName);
    }

    findSceneIndexByPath(path: ScenePath) {
        if (!path) {
            return undefined;
        }
        for (let index = 0; index < this.scenes.length; index++) {
            const element = this.scenes[index];
            if (element.name === path.sceneName) {
                return index;
            }
        }
    }

    findSceneByName(name?: string) {
        for (const scene of this.scenes) {
            if (scene.name === name) {
                return scene;
            }
        }
    }

    drawFrame(icanvas: InteractiveCanvas) {
        for (let each in this.scenes) {
            let i = this.scenes[each];
            i.drawFrame(icanvas);
        }
    }

    mousedown(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousedown(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mouseup(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mouseup(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    mousemove(icanvas: InteractiveCanvas, e: MouseEvent) {
        let event = new InteractiveEvent(icanvas, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].mousemove(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    keydown(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keydown(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    keyup(icanvas: InteractiveCanvas, ievent: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].keyup(ievent);
            if (ievent.isStopPropagation) {
                break;
            }
        }
    }
    onTouchTap(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchTap(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchPan(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchPan(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchSwipe(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchSwipe(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchDistance(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchDistance(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchRotate(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchRotate(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    onTouchGesture(e: Event) {
        let event = new InteractiveEvent(undefined, e);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].onTouchGesture(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    file_dropped(e: DragEvent, files: any[]) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].file_dropped(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }
    drag_file(e: DragEvent, files: any[]) {
        let event = new DragDropEvent(this.active_scene?.icanvas, e, files);
        event.isDrag = true;
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].drag_file(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate_event(event: InteractiveEvent) {
        for (let each = this.scenes.length - 1; each >= 0; each--) {
            this.scenes[each].activate_event(event);
            if (event.isStopPropagation) {
                break;
            }
        }
    }

    activate(value: any, value_context: any) {
        let event = new InteractiveEvent();
        event.activate_value = value;
        event.activate_value_context = value_context;
        this.activate_event(event);
    }

    selectNextSceneInteractive(increment = 1) {
        let selectable = [];

        for (const each_scene of this.scenes) {
            let scene_selectable = each_scene.sceneInteractiveLayerInteractives.interactives.filter(
                (item) => item.isSelectable(),
            );
            selectable.push(...scene_selectable);
        }

        let index = selectable.findIndex((item) => item.isSelected);

        if (index === -1) {
            index = selectable.findIndex((item) => item.json.isDefaultSelection);
            if (index !== -1) {
                index -= increment;
            }
        } else {
            selectable[index].setSelected(false);
        }

        index += increment;
        index = index % selectable.length;

        if (index < 0) {
            index += selectable.length;
        }

        selectable[index].setSelected(true);
    }

    getSelectedSceneInteractive() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    return each;
                }
            }
        }

        return undefined;
    }

    selectNone() {
        for (const each_scene of this.scenes) {
            for (let each of each_scene.sceneInteractiveLayerInteractives.interactives) {
                if (each.isSelected) {
                    each.setSelected(false);
                }
            }
        }
    }
    /**
     *
     * @param {UpdateContext} update_context
     */
    update(update_context: UpdateContext) {
        this.visual_elements.update(update_context);
    }

    excludeSceneLayer(name: string) {
        if (this.ux_scene && name === CommonScenes.UXSceneName) {
            this.ux_scene.stop();
            let index = this.scenes.indexOf(this.ux_scene);
            if (index !== -1) this.scenes.splice(index, 1);
            this.ux_scene = undefined;
        }
    }
    unexcludeSceneLayer(name: string) {
        if (!this.ux_scene && name === CommonScenes.UXSceneName) {
            this.startUXScene();
            if (this.ux_scene) {
                this.scenes.push(this.ux_scene);
            }
        }
    }
}
