import { InteractiveCanvas } from "../../interactive_canvas";
import { onDrawingFunction } from "../OnDrawingParams";
import { ResourceCanvasElementInterface } from "../resourceCanvasElements/ResourceCanvasElement";
import { ResourceInterface } from "../resources/resourceInterface";

export class CanvasElement {
    is_invalidating_draw: boolean;
    resource?: ResourceInterface;
    resource_canvas_element?: ResourceCanvasElementInterface;
    draw_order: number;
    isHidden: boolean;
    opacity: number;
    icanvas?: InteractiveCanvas;
    autoInvalidateFrequencyPerSecond?: number;
    isFirstTimeCanvasAdd = true;
    errorLog: any[] = [];
    onError: (elm: CanvasElement, error: string) => void;
    onDrawing: onDrawingFunction;

    constructor(
        resource?: ResourceInterface,
        resource_canvas_element?: ResourceCanvasElementInterface,
        is_invalidating_draw = true,
    ) {
        this.resource = resource;
        this.is_invalidating_draw = is_invalidating_draw;
        this.draw_order = 1;
        this.resource_canvas_element = resource_canvas_element;
    }

    toSourceURL(): string | undefined {
        return undefined;
    }

    addError(error: string, notify = true, isLog = true, returnValue: any = false) {
        this.errorLog.push(error);

        if (isLog) {
            console.error(error);
        }

        if (notify) {
            this.onError?.(this, error);
        }

        return returnValue;
    }

    onCanvasResized() {}

    draw() {}

    addedToInteractiveCanvas(icanvas: InteractiveCanvas) {
        this.icanvas = icanvas;
        this.icanvas.addAutoInvalidateFrequencyPerSecond(
            this,
            this.autoInvalidateFrequencyPerSecond,
        );
        this.addingToInteractiveCanvas();
        this.isFirstTimeCanvasAdd = false;
    }

    removedFromInteractiveCanvas() {
        this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);

        this.removeingFromInteractiveCanvas();
        this.icanvas = undefined;
    }

    addingToInteractiveCanvas() {}

    removeingFromInteractiveCanvas() {}

    invalidate() {
        this.is_invalidating_draw = true;
    }

    validate() {
        this.is_invalidating_draw = false;
    }

    setHidden(value: boolean) {
        if (value == this.isHidden) {
            return;
        }
        if (value) {
            this.isHidden = true;
            // console.log("t");
        } else {
            this.isHidden = false;
            // console.log("f");
        }

        this.isHidden = value;
        this.invalidate();
    }

    isLoading() {
        return this.resource?.isLoading();
    }

    getFirstLoadingPromise() {
        if (this.resource?.getLoadingPromise)
            return this.resource?.getLoadingPromise();
    }

    setDrawOrder(value: number) {
        this.draw_order = value;
    }

    setAutoInvalidateFrequencyPerSecond(value: number | undefined) {
        this.autoInvalidateFrequencyPerSecond = value;
        this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);
        this.icanvas?.addAutoInvalidateFrequencyPerSecond(
            this,
            this.autoInvalidateFrequencyPerSecond,
        );
    }
}
