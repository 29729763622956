import { WebApplication } from "../../webApplication";
import { ActionWithValue } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

/**
 *
 */
export class SetApplicationSettingAction extends ActionWithValue {
    application?: WebApplication;

    constructor(verb: string, name: string | undefined, json: any, application?: WebApplication) {
        super(verb, name, json);
        this.application = application;
    }
    /**
     *
     */
    run(interactive: SceneObjectInterface | undefined) {
        console.log(`action: SetApplicationSettingAction ${this.value} ${this.value_context}`);
        if (this.application) this.application.setSetting(this.value, this.value_context);
    }
}
