import { GettingReadyCallbackCollector } from "./GettingReadyCallbackCollector";
/**
 *
 */
export class SceneChangeRequest {
    isPushNavigationHistory: boolean;
    location: any;
    error: any;
    loading_promise?: Promise<any>;
    startedAt: number;
    isCancled: boolean;
    isPrimary: boolean;
    isCompleted: boolean;
    callback_collector: GettingReadyCallbackCollector;
    callback_collector_promise?: Promise<any>;

    constructor(location: any, isPushNavigationHistory = true) {
        this.location = location;
        this.isPushNavigationHistory = isPushNavigationHistory;
    }
}
