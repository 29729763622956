//@ts-check

import { ScenePath } from "./sceneGraph/ScenePath";
import { SceneChangeRequest } from "./sceneGraph/SceneChangeRequest";
import { CustomerAccount } from "./customerAccount";
import { Simulation } from "./simulation";

/**
 *
 */
export class Player {
    account: CustomerAccount;
    json: any;
    simulation: Simulation;
    activeLocation: any;

    constructor(account: CustomerAccount, simulation: Simulation) {
        this.account = account;
        this.json = account.json_player;
        this.simulation = simulation;

        if (this.json.tokenCount === undefined) {
            this.json.tokenCount = 0;
        }
        if (this.json.homeLocation === undefined) {
            //var sg = this.sceneGraphSet.defaultNavigationSceneGraph;
            this.json.homeLocation = new ScenePath("rustic-log-cabin", "cabin-splash-screen");
        }
        if (this.json.location === undefined) {
            this.json.location = this.json.homeLocation;
        }
        if (this.json.navigationHistory === undefined) {
            this.json.navigationHistory = [this.json.location];
        }
    }

    get sceneGraphSet() {
        return this.simulation.sceneGraphSet;
    }

    // get simulation() {
    //   return this.sceneGraphSet.simulation;
    // }

    get sceneGraphNode() {
        return this.simulation.sceneGraphNode;
    }

    get navigationHistory() {
        return this.json.navigationHistory;
    }

    set navigationHistory(v) {
        this.json.navigationHistory = v;
    }

    onSceneChangeRequestSuccess(request: SceneChangeRequest) {
        this.json.location = request.location;
        this.activeLocation = request.location;

        // if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
        if (request.isPushNavigationHistory) {
            this.navigationHistory.push(request.location);

            if (this.navigationHistory.length > 100) {
                this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
            }
        }
        // }
    }

    startChangeLocation(path: any, isPushNavigation = true, fallbackPathOnError: any = undefined) {
        let request = new SceneChangeRequest(path, isPushNavigation);
        let fallback_request_on_error;
        if (fallbackPathOnError) {
            fallback_request_on_error = new SceneChangeRequest(
                fallbackPathOnError,
                isPushNavigation,
            );
        }
        // request.replace_location = this.active_location;
        this.simulation.startSceneChange(request, fallback_request_on_error);
        // if (this.json.location === path) {
        //   return;
        // }

        // this.sceneGraphSet.tryPopulateDefaultPaths(path);
        // var success = this.sceneGraphNode.TryReplaceScene(this.sceneGraphSet, this.json.location, path);

        // if (success) {

        //   this.simulation.selectionMode.startState();
        //   this.json.location = path;

        //   if (this.navigationHistory.length == 0 || this.navigationHistory[this.navigationHistory.length - 1] !== path) {
        //     if (isPushNavigation) {
        //       this.navigationHistory.push(path);

        //       if (this.navigationHistory.length > 100) {
        //         this.navigationHistory = this.navigationHistory.splice(0, 100 / 2);
        //       }
        //     }
        //   }

        //   this.sceneGraphNode.invalidate();
        // }
    }

    // initializeSceneGraphNode() {
    //   if (!this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.location, true)) {
    //     console.warn(`scene path not found in scene graph set ${JSON.stringify(this.json.location)}`);
    //     if (this.sceneGraphNode.tryAddAndStartScenePath(this.sceneGraphSet, this.json.homeLocation, true)) {
    //       this.json.location = this.json.homeLocation;
    //       this.navigationHistory.push(this.json.location);
    //     }
    //   }

    //   this.simulation.selectionMode.startState();
    // }

    canNavigateBack() {
        return this.navigationHistory.length > 1;
    }

    navigateBack() {
        if (this.canNavigateBack()) {
            this.navigationHistory.pop();
            let path = this.navigationHistory[this.navigationHistory.length - 1];
            this.startChangeLocation(path, false);
        }
    }

    navigateHome() {
        this.startChangeLocation(this.json.homeLocation);
    }
}
