import { Action } from "../actions/action";
import { SceneObjectInterface } from "../SceneObjectInterface";

export class RefreshPageAction extends Action {
    constructor(verb: string, name: string | undefined, json?: any) {
        super(verb, name);
    }

    run(interactive: SceneObjectInterface | undefined) {
        console.log("action:RefreshPageAction");
        location.reload();
    }
}
