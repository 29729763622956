import IConnectedStems from "./interfaces/IConnectedStems";
import { Stem } from "./stem";

export class NullConnectedStems implements IConnectedStems {
    addReference(stem: Stem) {}

    removeReference(stem: Stem) {}

    findConnectionForStem(stem: Stem) {
        return undefined;
    }

    findConnectionForEffectStem(stem: Stem) {
        return undefined;
    }

    findOrAddConnectionForStem(stem: Stem) {
        return undefined;
    }

    loadStem(stem: Stem) {}

    unloadStem(stem: Stem) {}

    playStem(stem: Stem) {}

    updateStem(stem: Stem) {}

    setGainStem(stem: Stem, amount: number) {}

    stopStem(stem: Stem) {}

    addConnectionForEffectStem(stem: Stem) {}

    playSoundEffect(stem: Stem) {}
}
