// @ts-check

import { AudioAmbiance } from "./audioAmbiance";
import { PlaylistManager } from "./playlistAPI/playlistManager.ts";
import { resolveRadioOn, resolveRadioPlaylistId } from "./playlistAPI/Radio";
import { Stem } from "./stem";
import { Audio } from "./webAudio";
import { WebAudioInterface } from "./WebAudioComponent";

interface PlaylistStatus {
    active: string[]; // Assuming `keys` are strings
    inactive: string[];
}
/**
 *
 */
export class AudioAmbianceGroup {
    collection: AudioAmbiance[] = [];
    audio: Audio;
    playlistManager: PlaylistManager;
    /**
     *
     * @param {import('./WebAudioComponent.js').WebAudioInterface} audio
     */
    constructor(audio: WebAudioInterface) {
        this.audio = audio.player;
        this.playlistManager = audio.playlistManager;
    }
    /**
     *
     */
    onStartAudioOnGesture() {}
    /**
     *
     * @param {AudioAmbiance} ambiance
     */
    add(ambiance: AudioAmbiance) {
        if (!ambiance) {
            return;
        }

        if (this.collection.filter((each) => each === ambiance).length > 0) {
            return;
        }

        let to_soundscape = ambiance.get_soundscape();

        this.loadSoundscape(to_soundscape);
        this.playSoundscape(to_soundscape);
        this.collection.push(ambiance);
    }

    transition(ambiance_from: AudioAmbiance, ambiance_to: AudioAmbiance) {
        const playlistsTo = ambiance_to.playlists;

        const activePlaylists = playlistsTo.map((config) => {
            const id = config.playlist.name;
            if (config.playlist.type === "radio") {
                const radioId = resolveRadioPlaylistId(config.name);
                if (!resolveRadioOn(config.name)) {
                    return { id: undefined, gain: 0 };
                }
                return { id: radioId, gain: config.playlist.gain };
            } else {
                return { id: id, gain: config.playlist.gain };
            }
        });

        this.playlistManager.keys.forEach((id: string) => {
            const match = activePlaylists.filter((config) => id === config.id);
            if (match.length > 0) {
                this.playlistManager.setActive(id, true);
                this.playlistManager.updateGain(id, match[0].gain);
            } else {
                this.playlistManager.setActive(id, false);
            }
        });

        const from_soundscape = ambiance_from.get_soundscape();
        const to_soundscape = ambiance_to.get_soundscape();

        from_soundscape.forEach((each) => {
            const found = each.findInArray(to_soundscape);
            if (found) {
            } else {
                this.audio.connectedStems.stopStem(each);
            }
        });
        ambiance_from.onListenLocationExit(this);

        to_soundscape.forEach((each) => {
            const found = each.findInArray(from_soundscape);
            if (found) {
                this.audio.connectedStems.updateStem(each);
            } else {
                this.audio.connectedStems.loadStem(each);
                this.audio.connectedStems.playStem(each);
            }
        });

        this.collection.push(ambiance_to);
    }
    /**
     *
     * @param {AudioAmbiance} ambiance
     */
    removeAmbianceFromCollection(ambiance: AudioAmbiance): void {
        const index = this.collection.indexOf(ambiance);
        if (index > -1) {
            this.collection.splice(index, 1);
        }
    }
    /**
     *
     * @param {AudioAmbiance} ambiance
     */
    remove(ambiance: AudioAmbiance): void {
        if (!ambiance) {
            return;
        }
        let from_soundscape = ambiance.get_soundscape();
        this.stopSoundscape(from_soundscape);
        this.removeAmbianceFromCollection(ambiance);
        ambiance.onListenLocationExit(this);
    }
    /**
     *
     * @param {Stem[]} soundscape
     */
    playSoundscape(soundscape: Stem[]) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.playStem(stem);
        });
    }
    /**
     *
     * @param {Stem[]} soundscape
     */
    stopSoundscape(soundscape: Stem[]) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.stopStem(stem);
        });
    }
    /**
     *
     * @param {Array.<import('./stem').Stem>} soundscape
     */
    loadSoundscape(soundscape: Stem[]) {
        soundscape.forEach((stem) => {
            this.audio.connectedStems.loadStem(stem);
        });
    }
}
