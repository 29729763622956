import { PlaylistItem } from "./types";

/**
 * The `Playlist` interface, providing a structure to manage a collection of audio items
 * in a playlist. It supports essential playlist operations such as navigating to the next or
 * previous item, adding new items, and retrieving the currently active item.
 *
 * Key Features:
 * - **Unique Identifier**: Each playlist instance is uniquely identified by an `id`.
 * - **Looping**: Supports looping functionality to cycle through the playlist seamlessly.
 * - **Item Management**: Allows adding and retrieving playlist items.
 * - **Navigation**: Methods to move to the next or previous item in the playlist.
 * - **State Management**: Tracks the active item and supports muting and gain adjustment.
 */
export interface Playlist {
    id: string;
    isActive: boolean;
    gain: number;
    length: number;
    isLooping: boolean;
    getItems: PlaylistItem[];
    addItem(item: PlaylistItem): number;
    getActiveItem(): PlaylistItem;
    next(): PlaylistItem;
    prev(): PlaylistItem;
}

export class AudioPlaylist implements Playlist {
    readonly id: string;
    private items: PlaylistItem[] = [];
    private activeIndex: number = 0;
    isLooping: boolean;
    gain: number = 1.0;
    isActive: boolean;
    isMuted: boolean;

    constructor(id: string, isLooping: boolean = true) {
        this.id = id;
        this.isLooping = true;
    }

    get length() {
        return this.items.length;
    }

    get isEmpty() {
        return this.items.length === 0;
    }

    get getItems() {
        return this.items.slice();
    }
    next(): PlaylistItem {
        this.activeIndex = this.getNextIndex();
        return this.getActiveItem();
    }

    prev(): PlaylistItem {
        this.activeIndex = this.getPrevIndex();
        return this.getActiveItem();
    }

    addItem(item: PlaylistItem) {
        this.items.push(item);
        return this.length;
    }

    getActiveItem(): PlaylistItem {
        return this.items[this.activeIndex];
    }

    private getPrevIndex() {
        return (this.activeIndex - 1 + this.items.length) % this.items.length;
    }

    private getNextIndex() {
        return (this.activeIndex + 1 + this.items.length) % this.items.length;
    }
}
