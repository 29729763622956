import { NullPlaylistJson, NullPlaylistsJson } from "./nullTypes";
import { PlaylistJSON, PlaylistsJSON } from "./types";

// Defualt gain for all radios
const DEFAULT_GAIN = 0.9;
let config: PlaylistsJSON = new NullPlaylistsJson("unassigned null playlists json");

export type Radio = {
    defaultGain: number;
    station: string;
    on: boolean;
};

type Radios = {
    [key: string]: Radio;
};

const radios: Radios = {
    "cabin-radio": {
        station: "lofi-piano",
        defaultGain: DEFAULT_GAIN,
        on: false,
    },
    "tower-radio": {
        station: "debug",
        defaultGain: DEFAULT_GAIN,
        on: false,
    },
};

export const setPlaylistConfig = async (
    url: string = "/assets/data/audio.playlists.json",
): Promise<void> => {
    const response = await fetch(url); // all playlists
    if (!response.ok) {
        console.error(response);
        throw new Error("response");
    }
    const data = (await response.json()) as PlaylistsJSON;

    // config = { ...data, playlists: config.playlists };
    config = { ...data };
};

export const getRadioSettings = () => {
    return {
        ...radios,
    };
};

export const getRadio = (radioName: string) => {
    return {
        ...radios[radioName],
    };
};

const getActiveRadios = (): string[] => {
    return Object.keys(radios).filter((radioName) => {
        return radios[radioName].on;
    });
};

export const changeStation = (radio: string, playlistName: string) => {
    if (radios[radio] === undefined) {
        console.error("passed invalid radio name");
        return false;
    }
    radios[radio].station = playlistName;
};

export const toggleRadio = (radio: string): boolean => {
    if (radios[radio] === undefined) {
        console.error("passed invalid radio name");
        return false;
    }
    radios[radio].on = !radios[radio].on;
    return radios[radio].on;
};

export const getAllRadioStations = (): PlaylistJSON[] => {
    return config.playlists.filter((playlist) => playlist.type === "radio");
};

export const resolveRadioPlaylist = (radio: string): PlaylistJSON => {
    try {
        const validStations = radios;
        const station = validStations[radio].station;
        const matchingPlaylists = config.playlists.filter(
            (playlistData) => station === playlistData.name,
        );
        if (matchingPlaylists?.length > 1 || matchingPlaylists.length === 0) {
            const message = `check config for extra playlists asscoiated with ${radio}`;
            throw new Error(message);
        }
        return matchingPlaylists[0];
    } catch (error: any) {
        console.error(error.message);
        return new NullPlaylistJson(error.message);
    }
};

export const resolveRadioPlaylistId = (radio: string): string => {
    return resolveRadioPlaylist(radio).name;
};

export const getActivePlaylistIds = (): string[] => {
    const activeRadios = getActiveRadios();
    return activeRadios.map((radioName) => {
        return resolveRadioPlaylistId(radioName);
    });
};

export const resolveRadioOn = (radio: string): boolean => {
    return radios[radio].on;
};

export const getDefaultRadioGain = (radio: string): number => {
    return getRadio(radio).defaultGain;
};
