import { WebApplication } from "../../../webApplication";
import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "../../../sceneGraph/GettingReadyCallbackCollector";
import { RectangleGeometry } from "../../../geometry/RectangleGeometry";
import { onDrawingFunction, OnDrawingParams } from "../OnDrawingParams";
import { UpdateContext } from "../../../update";
import { FileInfoType } from "../../../LocalServerFileCache";
import { ResourceInterface } from "../resources/resourceInterface";
import { VisualElement } from "../visualElement";
import { CanvasElement } from "../canvasElements/CanvasElement";
import { Canvas } from "../canvasStack";
import IStoppableCanvasElement from "../interfaces/IStoppableCanvasElement";

export interface ResourceCanvasElementInterface {
    setDrawOrder(value: number): void;
    setHidden(value: boolean): void;
    toSourceURLNameExt(): string | undefined;
    start_loading(value: GettingReadyCallbackCollector): void;
    isLoading(): boolean | undefined;
    update(update_context: UpdateContext): void;
    start(): void;
    stop(next?: IStoppableCanvasElement): void;
    onDrawing: onDrawingFunction;
    isPauseMSEOnStart(): boolean;
    url_file_info?: FileInfoType;
    visual_resource: ResourceInterface;
    isReady: boolean;
    isError: boolean;
    geometry: RectangleGeometry;
    vis: VisualElement;
}

export class ResourceCanvasElement {
    vis: VisualElement;
    visual_resource: ResourceInterface;
    canvasElement?: CanvasElement;
    isHidden: boolean;
    onDrawing: onDrawingFunction;
    isReady = false;
    isError: boolean;
    drawOrder?: number;
    isCanceled: boolean;

    constructor(vis: VisualElement, visual_resource: ResourceInterface) {
        this.vis = vis;
        this.visual_resource = visual_resource;

        if (vis.scene.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log("new:ResourceCanvasElement")
        }
    }

    get geometry() {
        if (this.vis.obj.visual_geometry) {
            return this.vis.obj.visual_geometry;
        }
        if (this.vis.obj.geometry) {
            return this.vis.obj.geometry;
        }
        if (this.visual_resource) {
            let size = this.visual_resource.pixel_size();
            let result = new RectangleGeometry();
            result.initialize(0, 0, size[0], size[1]);
            return result;
        }
        let result = new RectangleGeometry();
        result.initialize(0, 0, 0, 0);
        return result;
    }

    get url_file_info() {
        return this.visual_resource?.url_file_info;
    }

    get isLoadingSuccess() {
        if (this.visual_resource === undefined) {
            return false;
        }
        if (this.visual_resource.isLoaded !== true) {
            return false;
        }
        this.visual_resource.checkForErrors();
        if (this.visual_resource.isError === true) {
            return false;
        }
        return true;
    }

    toSourceURLNameExt() {
        return this.visual_resource.resource_request?.toURLNameAndExtension();
    }

    toSourceURLName() {
        return this.visual_resource.toSourceURLName?.();
    }

    toSourceURL() {
        return this.canvasElement?.toSourceURL?.();
    }

    get url() {
        return this.toSourceURL();
    }

    start_loading(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        if (this.visual_resource === undefined) {
            this.isReady = true;
            return;
        }

        let promise = new Promise((resolve, reject) => {
            this.visual_resource.onVisualLoaded = (s) => {
                this.isReady = true;
                resolve("Operation successful!");
            };
            this.visual_resource.start_loading(this.vis.server_file_cache, this);
        });
        let callback = new GettingReadyCallback();
        callback.addPromise(promise);
        gettingReadyCallbackCollector.add(callback);
    }

    canvasElement_on_drawing(params: OnDrawingParams) {
        this.onDrawing?.(params);
    }

    start() {
        if (!this.canvasElement) {
            this.canvasElement = this.visual_resource.createCanvasElement(this);

            if (this.canvasElement) {
                this.canvasElement.onDrawing = this.canvasElement_on_drawing.bind(this);
                this.canvasElement.onError = (elm, err) => this.onElementError(elm, err);
                this.canvasElement.setHidden(this.isHidden);

                if (this.drawOrder) {
                    this.canvasElement.draw_order = this.drawOrder;
                } else {
                    this.canvasElement.draw_order = this.vis.obj.scene.draw_order;
                }
            }
        }

        this.vis.resource_displayed(this.visual_resource, true);
        this.visual_resource.start(this);

        this.vis.icanvas.addElement(this.canvasElement);
    }

    isPauseMSEOnStart() {
        return true;
    }

    onElementError(elm: CanvasElement, err: string) {
        let pause_error = "The play() request was interrupted by a call to pause()";
        if (err.includes(pause_error)) {
            return;
        }

        this.visual_resource.notifyError();

        this.isError = true;
        this.vis.onResourceCanvasElementError(this);
    }

    stop(next_resource_canvas_element?: ResourceCanvasElementInterface) {
        if (this.visual_resource.isLoading()) {
            this.visual_resource.cancelLoading?.();
            this.isCanceled = true;
        } else {
            this.visual_resource.stop();
        }

        this.removeCanvasElement();
    }
    // collectCanvasElements(result) {
    //     if (this.canvasElement) {
    //         result.push(this.canvasElement);
    //     }
    // }

    setHidden(value: boolean) {
        this.canvasElement?.setHidden(value);
        this.isHidden = value;
    }

    setDrawOrder(value: number) {
        this.drawOrder = value;
        this.canvasElement?.setDrawOrder(value);
    }

    removeCanvasElement() {
        if (this.canvasElement) {
            this.vis.icanvas.removeElement(this.canvasElement);
        }
    }

    isLoading() {
        return this.visual_resource?.isLoading();
    }

    // toRect(scaleToCanvas = true) {
    //     return this.visual_resource?.toRect(scaleToCanvas ? this.vis.icanvas : undefined);
    // }

    update() {}
}
