import { AudioVisualScriptResource } from "../../audio_visual/visual/resources/AudioVisualScriptResource";
import { SceneGraphAudioVisualScript } from "./SceneGraphAudioVisualScript";

export class SceneGraphAudioVisualScripts {
    json: any;
    av_script_resource: AudioVisualScriptResource;

    constructor(json: any, av_script_resource: AudioVisualScriptResource) {
        this.json = json;
        this.av_script_resource = av_script_resource;
    }

    initializeFromJson(): void {}

    getScriptByName(name: string): SceneGraphAudioVisualScript | undefined {
        for (const each in this.json) {
            const script = this.json[each];
            if (each === name) {
                const obj = new SceneGraphAudioVisualScript(script, this);
                obj.initializeFromJson();
                return obj;
            }
        }
        return undefined;
    }
    getDefaultScript(): SceneGraphAudioVisualScript | undefined {
        for (const each in this.json) {
            const script = this.json[each];
            if (script.isDefault) {
                const obj = new SceneGraphAudioVisualScript(script, this);
                obj.initializeFromJson();
                return obj;
            }
        }
        return undefined;
    }
    getStates() {
        return this.av_script_resource.state_set;
    }
}
